export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function emptyTask(id, board, stage) {
  return {
    id: id,
    name: '',
    description: '', // описание (произвольным текстом)
    date: new Date(),
    startDate: null,
    endDate: null,
    boardId: board,
    stageId: stage,
    projectId: '00000000-0000-0000-0000-000000000000',
    assignedId: '00000000-0000-0000-0000-000000000000' // назначенный исполнитель

    // users: [],
    // files: [],
    // comments: [],
    // reminders: [],
    // invitations: []
  }
}
