import http from './http'
const stages = {
  getAll: () => http.get('/allstages'),
  getById: (id) => http.get(`/allstages/${id}`),
  save: (lastSelectedId, data) =>
    http.post(
      lastSelectedId ? `/allstages?fromid=${lastSelectedId}` : '/allstages',
      data
    )
}
export default stages
