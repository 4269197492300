import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Task from '../components/Task.vue'
import Settings from '../components/Settings.vue'
import TaskList from '../components/TaskList.vue'
import Help from '../components/Help.vue'
import NewSpace from '../components/NewSpace.vue'
import UserAgreement from '../components/UserAgreement.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Tender Flow - Управление задачами бизнеса'
    },
    children: [
      {
        path: 'settings',
        component: Settings,
        meta: { title: 'Tender Flow - Настройки' }
      },
      {
        path: 'task/:id',
        component: Task,
        meta: { title: 'Tender Flow - Задача' }
      },
      {
        path: 'tasklist',
        component: TaskList,
        meta: { title: 'Tender Flow - Список задач' }
      },
      {
        path: 'help',
        component: Help,
        meta: { title: 'Tender Flow - Справка' }
      },
      {
        path: 'newspace',
        component: NewSpace,
        meta: { title: 'Tender Flow - Создание окружения' }
      }
    ]
  },
  {
    path: '/user-agreement',
    name: 'UserAgreement',
    component: UserAgreement,
    meta: {
      title: 'Tender Flow - Пользовательское соглашение'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)
  const previousNearestWithTitle = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithTitle) {
    document.title = previousNearestWithTitle.meta.title
  }

  next()
})

export default router
