<template>
  <div class="md:max-w-5xl mx-auto w-full h-full text-sm p-3">
    <h2 class="text-lg pb-2" v-if="!hideTitle">
      {{ currentUser.currentSpaceState.spaceName }}
    </h2>
    <t-table>
      <template slot="tbody" slot-scope="props">
        <tbody :class="props.tbodyClass">
          <tr :class="props.trClass">
            <td :class="props.tdClass">Статус окружения</td>
            <td :class="props.tdClass">
              <strong> {{ currentUser.currentSpaceState.statusName }}</strong>
            </td>
          </tr>
          <tr
            :class="props.trClass"
            v-for="(tdef, idx) in currentUser.currentSpaceState.tarifPlanArrDef"
            v-bind:key="idx"
          >
            <td :class="props.tdClass">
              {{ tdef[0] }}
            </td>
            <td :class="props.tdClass">
              {{ tdef[1] }}
            </td>
          </tr>
          <tr :class="props.trClass">
            <td :class="props.tdClass">Активных пользователей</td>
            <td :class="props.tdClass">
              <strong> {{ currentUser.currentSpaceState.usersCount }}</strong>
            </td>
          </tr>
          <tr :class="props.trClass">
            <td :class="props.tdClass">Баланс</td>
            <td :class="props.tdClass">
              <strong> {{ currentUser.currentSpaceState.saldo }} р.</strong>
              <span v-if="currentUser.currentSpaceState.expiresAt">
                , закончится
                {{ formatDateDisplay(currentUser.currentSpaceState.expiresAt) }}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </t-table>

    <div
      class="flex justify-center pt-4"
      v-if="currentUser.currentSpaceState.payable && isAdmin"
    >
      <!-- TODO: создать инвоис и перейти в бота -->
      <t-button type="button"> Оплатить </t-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['hideTitle'],
  computed: mapGetters(['currentUser', 'isAdmin'])
}
</script>
