<template>
  <div class="md:max-w-5xl mx-auto w-full h-full">
    <t-card>
      <!-- header="Проекты" variant="blackboldcaption" -->
      <div class="flex-1 w-full md:rounded-lg bg-white min-h-full space-y-2">
        <t-rich-select
          v-model="selected"
          :options="selAllProjects"
          placeholder="Проекты..."
          noResultsText="Не найдено..."
          searchBoxPlaceholder="Найти..."
        >
          <template slot="dropdownDown" slot-scope="{ query }">
            <div class="text-center">
              <button
                type="button"
                class="
                  block
                  w-full
                  px-3
                  py-2
                  text-white
                  bg-blue-700
                  border
                  hover:bg-blue-800
                  md:rounded
                "
                @click="createOption(query)"
              >
                Создать {{ query }}
              </button>
            </div>
          </template>
        </t-rich-select>

        <div v-show="editing" class="flex flex-col space-y-2 ml-4">
          <t-input-group label="Наименование">
            <t-input placeholder="Наименование..." v-model="current.name" />
          </t-input-group>

          <t-input-group label="Описание">
            <t-textarea
              v-model="current.description"
              placeholder="Описание..."
            />
          </t-input-group>

          <t-input-group label="Заказчик">
            <t-rich-select
              v-model="current.customerId"
              :options="selAllCustomers"
              placeholder="Заказчик..."
              noResultsText="Не найдено..."
              searchBoxPlaceholder="Найти..."
            ></t-rich-select>
          </t-input-group>

          <t-input-group label="Пользователи">
            <template>
              <t-rich-select
                v-model="projectUsers"
                :options="selAllUsers"
                multiple
                :close-on-select="false"
                placeholder="Пользователи проекта..."
                noResultsText="Не найдено..."
                searchBoxPlaceholder="Найти..."
              ></t-rich-select>
            </template>
          </t-input-group>

          <div class="flex justify-end items-center space-x-2">
            <t-button variant="secondary" @click="cancelCurrent"
              >Отмена</t-button
            >
            <t-button @click="saveProject">Сохранить</t-button>
          </div>
        </div>
      </div>
    </t-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import projects from '@/api/projects'
import { generateUUID } from './const'
import { catchErr } from '@/error'

export default {
  data() {
    return {
      selected: null,
      current: {},
      projectUsers: [],
      users: {},
      editing: false
    }
  },
  mounted() {
    this.fetchData()
  },
  watch: {
    selected: 'loadCurrent'
  },
  computed: {
    ...mapGetters(['selAllProjects', 'selAllCustomers', 'selAllUsers'])
  },
  methods: {
    ...mapActions(['fetchAllProjects', 'fetchAllCustomers', 'fetchAllUsers']),

    fetchData() {
      this.fetchAllProjects()
      this.fetchAllCustomers()
      this.fetchAllUsers()
    },
    cancelCurrent() {
      this.current = {
        id: '',
        name: '',
        description: '',
        customerId: ''
      }
      this.projectUsers = []
      this.selected = null
      this.editing = false
    },
    loadCurrent() {
      if (this.selected) {
        projects
          .getById(this.selected)
          .then((response) => {
            this.current = response.data
            this.editing = true
            projects
              .getUsers(this.selected)
              .then((response) => {
                let plist = []
                for (let p of response.data) {
                  plist.push(p.userId)
                }
                this.projectUsers = plist
              })
              .catch((error) => {
                if (error.response.status == 404) {
                  this.projectUsers = []
                } else {
                  catchErr(error)
                }
              })
          })
          .catch((error) => {
            if (error.response.status == 404) {
              this.current = {
                id: this.selected,
                name: '',
                description: '',
                customerId: ''
              }
              this.projectUsers = []
              this.editing = true
            } else {
              catchErr(error)
            }
          })
      } else {
        this.current = {
          id: '',
          name: '',
          description: '',
          customerId: ''
        }
        this.projectUsers = []
      }
    },

    createOption(text) {
      this.current = {
        id: generateUUID(),
        name: text,
        description: '',
        customerId: ''
      }
      this.selected = this.current.id
      this.editing = true
    },

    saveProject() {
      let saveData = {}
      for (let k in this.current) saveData[k] = this.current[k]

      projects
        .save(saveData)
        .then((response) => {
          this.current = response.data
          this.editing = false
          this.fetchAllProjects()
          this.$busEmit('flash', 'Проект сохранен')
        })
        .catch((error) => {
          catchErr(error)
        })

      let saveUsersData = []
      for (let k of this.projectUsers) saveUsersData.push(k)
      // console.log(saveUsersData)

      projects
        .saveUsers(this.selected, saveUsersData)
        // .then(() => {
        //   this.fetchAllProjects()
        // })
        .catch((error) => {
          catchErr(error)
        })
    }
  }
}
</script>
