import Vue from 'vue'
import router from './router'
import './assets/styles/tailwind.css'
import VueTailwind from 'vue-tailwind'
import { vtsettings } from './vtcomps'
import App from './App.vue'
import store from './store'
import Form from './components/form/Form.vue'
import FormLine from './components/form/FormLine.vue'
import VueCtkDateTimePicker from './components/VueCtkDateTimePicker'
import AccordionItem from './components/accordion-item/accordion-item.vue'
import SelectFlag from './components/SelectFlag'
import Avatar from './components/Avatar'
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { dom } from '@fortawesome/fontawesome-svg-core'

import { busEmit } from './eventBus'

library.add(far)
Vue.component('font-awesome-icon', FontAwesomeIcon)
dom.watch()

Vue.component('t-form', Form)
Vue.component('t-line', FormLine)
Vue.component('t-datetime', VueCtkDateTimePicker)
Vue.component('t-accordion-item', AccordionItem)
Vue.component('t-select-flag', SelectFlag)
Vue.component('t-avatar', Avatar)

Vue.config.productionTip = false

Vue.prototype.$busEmit = busEmit

Vue.use(VueTailwind, vtsettings)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
