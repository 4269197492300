<template>
  <div>
    <t-line noHeader v-if="taskFiles.length > 0">
      <ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
        <li
          class="
            pl-3
            pr-4
            py-3
            flex
            items-center
            justify-between
            text-sm
            flex-col
          "
          v-for="file in taskFiles"
          v-bind:key="file.id"
        >
          <div
            class="
              flex
              md:flex-row
              flex-col
              justify-between
              items-center
              w-full
              break-all
            "
          >
            <div class="flex-1 flex items-center">
              <!-- Heroicon name: solid/paper-clip -->
              <svg
                class="flex-shrink-0 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="md:ml-2 flex-1">
                {{ file.filetitle ? file.filetitle : file.filename }}
              </span>
            </div>

            <div class="ml-4 flex-shrink-0 flex md:flex-row flex-col space-x-2">
              <div class="md:text-sm text-xs">
                {{ file.user.fullname }}
                {{ formatDateTimeDisplay(file.updatedAt) }}
              </div>
              <a
                :href="
                  file.filetype == 1
                    ? file.filename
                    : '/api/v1/tasks/' + taskid + '/file/' + file.id
                "
                class="font-medium text-blue-600 hover:text-blue-500"
                target="_blank"
              >
                Открыть
              </a>
              <a
                href="#"
                class="font-medium text-blue-600 hover:text-blue-500"
                v-show="file.filetype == 1"
                @click="file.editLink = file.editLink ? '' : file.filename"
              >
                {{ file.editLink ? 'Закрыть' : 'Редактировать' }}
              </a>
            </div>
          </div>
          <div
            v-if="file.editLink"
            class="ml-4 flex flex-col items-center justify-center w-full py-2"
            style="height: 42rem"
          >
            <iframe :src="file.editLink" class="w-full h-full"></iframe>
          </div>
        </li>
      </ul>
    </t-line>
    <t-line noHeader gray>
      <div
        class="flex flex-col justify-center items-center md:p-4 p-2 space-y-2"
      >
        <TaskFileUpload
          v-bind:taskId="taskid"
          v-on:update:taskId="fetchFiles"
          v-show="!inputLinkShow"
        />
        <t-button
          @click="inputLinkShow = true"
          v-show="!inputLinkShow"
          variant="secondary"
          >...или добавьте ссылку на файл google</t-button
        >
        <a
          href="#"
          @click="inputLinkShow = false"
          v-show="inputLinkShow"
          class="text-xs underline pb-2"
          >...или загрузите файл</a
        >
        <t-input
          v-model="inputLink"
          v-show="inputLinkShow"
          placeholder="Ссылка на файл Google Sheets/Docs/Presentations"
        ></t-input>
        <t-button @click="sendLink" v-show="inputLinkShow"
          >Сохранить ссылку</t-button
        >
      </div>
    </t-line>
  </div>
</template>

<script>
import tasks from '@/api/tasks'
import TaskFileUpload from './TaskFileUpload'
import { catchErr } from '@/error'

export default {
  props: ['taskid'],
  components: {
    TaskFileUpload
  },
  data() {
    return {
      taskFiles: [],
      inputLinkShow: false,
      inputLink: ''
    }
  },
  mounted() {
    this.fetchData()
  },
  watch: {
    // при изменениях маршрута запрашиваем данные снова
    // $route: 'fetchData'
  },
  computed: {},
  methods: {
    fetchData() {
      this.fetchFiles()
    },
    fetchFiles() {
      tasks
        .getFiles(this.taskid)
        .then((response) => {
          let tfs = response.data
          tfs.forEach((tf, idx) => {
            tfs[idx].filetitle = ''
            tfs[idx].editLink = ''
          })
          this.taskFiles = tfs
          tfs.forEach((tf, idx) => {
            if (tf.filetype != 1) {
              return
            }
            fetch(tf.filename, {
              credentials: 'same-origin'
            })
              .then((response) => {
                return response.text()
              })
              .then((responseText) => {
                var parsedResponse = new window.DOMParser().parseFromString(
                  responseText,
                  'text/html'
                )
                tfs[idx].filetitle = parsedResponse.title
                this.taskFiles = tfs
              })
          })
        })
        .catch((error) => {
          if (error.response.status != 404) {
            this.taskFiles = []
            catchErr(error)
          }
        })
    },
    formatDateTimeDisplay(date) {
      let options = {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }
      let d = new Date(date)
      return d.toLocaleDateString('ru-RU', options)
    },
    sendLink() {
      if (!this.taskid) {
        return
      }
      tasks
        .postLink(this.taskid, {
          link: this.inputLink
        })
        .then(() => {
          this.inputLinkShow = false
          this.inputLink = ''
          this.fetchFiles()
        })
        .catch((error) => {
          catchErr(error)
        })
    }
  }
}
</script>
