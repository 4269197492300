<template>
  <div>
    <!--UPLOAD-->
    <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
      <div
        class="
          bg-blue-100
          hover:bg-blue-200
          border-blue-500 border-2 border-dashed
          cursor-pointer
          relative
          dropbox
          p-4
        "
        style=""
      >
        <input
          type="file"
          multiple
          :name="uploadFieldName"
          :disabled="isSaving"
          @change="
            filesChange($event.target.name, $event.target.files)
            fileCount = $event.target.files.length
          "
          accept="*"
          class="input-file"
        />
        <p v-if="isInitial" class="text-sm">
          Перетащите сюда файл(ы)<br />
          или нажмите здесь для выбора их из проводника
        </p>
        <p v-if="isSaving" class="text-sm">
          Загрузка {{ fileCount }} файлов...
        </p>
      </div>
    </form>
    <!--SUCCESS-->
    <div v-if="isSuccess" class="flex flex-col justify-center items-center">
      <h2 class="mb-2">Успешно загружено!</h2>
      <ul class="mb-2">
        <li
          v-for="(item, idx) in uploadedFiles"
          v-bind:key="idx"
          class="text-gray-700 mb-1"
        >
          {{ item.originalName }}
        </li>
      </ul>
      <t-button @click="reset()" variant="success">Загрузить еще</t-button>
    </div>
    <!--FAILED-->
    <div v-if="isFailed" class="flex flex-col justify-center items-center">
      <h2 class="mb-2">Ошибка загрузки!</h2>
      <p class="text-gray-700 mb-2">{{ uploadError }}</p>
      <t-button @click="reset()" variant="error">Попробуйте еще раз</t-button>
    </div>
  </div>
</template>

<script>
import tasks from '@/api/tasks'

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3

export default {
  props: ['taskId'],
  name: 'file-upload',
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'files'
    }
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED
    }
  },
  methods: {
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL
      this.uploadedFiles = []
      this.uploadError = null
    },
    save(formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING

      // console.log(formData)

      // this.uploadError = 'не реализовано'
      // this.currentStatus = STATUS_FAILED
      tasks
        .uploadFiles(this.taskId, formData)
        // upload(formData)
        // .then(
        //   wait(1500) // DEV ONLY: wait for 1.5s
        // )
        .then((response) => {
          let fns = []
          for (let p of response.data) {
            fns.push(p.filename)
          }
          this.uploadedFiles = fns
          this.currentStatus = STATUS_SUCCESS
          this.$emit('update:taskId')
        })
        .catch((err) => {
          this.currentStatus = STATUS_FAILED
          this.uploadError = err.response.data.error
        })
    },
    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData()
      if (!fileList.length) return
      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        formData.append(fieldName, fileList[x], fileList[x].name)
      })
      // save it
      this.save(formData)
    }
  },
  mounted() {
    this.reset()
  }
}
</script>

<style>
.dropbox {
  min-height: 80px; /* minimum height */
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 80px;
  position: absolute;
  cursor: pointer;
}

.dropbox p {
  text-align: center;
}
</style>
