// import http from '@/api/http'
import projects from '@/api/projects'
import customers from '@/api/customers'
import { catchErr } from '@/error'

export default {
  state: {
    projects: [],
    customers: []
  },
  mutations: {
    setProjects(state, projects) {
      state.projects = projects
    },
    setCustomers(state, customers) {
      state.customers = customers
    }
  },
  actions: {
    fetchAllProjects({ commit }) {
      projects
        .getAll()
        .then((response) => {
          commit('setProjects', response.data)
        })
        .catch((error) => {
          commit('setProjects', [])
          catchErr(error)
        })
    },
    fetchAllCustomers({ commit }) {
      customers
        .getAll()
        .then((response) => {
          commit('setCustomers', response.data)
        })
        .catch((error) => {
          commit('setCustomers', [])
          catchErr(error)
        })
    }
  },
  getters: {
    allProjects(state) {
      return state.projects
    },
    projectsCustomers(state) {
      let unidata = state.projects.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.customerId === thing.customerId)
      )

      let unicusts = [
        ...new Set(
          unidata.map((item) => ({
            id: item.customerId,
            name: item.customer.name || '(без заказчика)',
            disabled: true
          }))
        )
      ]

      let plist = [
        // {
        //   id: '[Все проекты]',
        //   name: '[Все проекты]'
        // }
      ]

      for (let uc of unicusts) {
        plist.push(uc)
        let pd = state.projects.filter((t) => {
          return t.customerId === uc.id
        })
        for (let p of pd) {
          plist.push(p)
        }
      }

      return plist
    },
    selAllProjects(state) {
      var result = {}
      state.projects.forEach((p) => {
        result[p.id] = p.name
      })
      return result
    },
    selAllCustomers(state) {
      var result = {}
      state.customers.forEach((c) => {
        result[c.id] = c.name
      })
      return result
    }
  }
}
