import { busEmit } from '@/eventBus'
export const catchErr = (error) => {
  if (!error.status) {
    // network error
    busEmit('flash', 'Нет соединения с сервером!', 'danger')
  }
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data)
    if (error.response.data.error) {
      busEmit('flash', error.response.data.error, 'danger')
    } else if (error.response.data.status) {
      busEmit('flash', error.response.data.status, 'danger')
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request)
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message)
  }
  console.log(error.config)
}
