import http from './http'
const projects = {
  getAll: () => http.get('/projects'),
  getById: (id) => http.get(`/projects/${id}`),
  save: (data) => http.post(`/projects/${data.id}`, data),
  del: (id) => http.delete(`/projects/${id}`),
  getUsers: (id) => http.get(`/projects/${id}/users`),
  saveUsers: (id, data) => http.post(`/projects/${id}/users`, data)
}
export default projects
