// https://ru.vuejs.org/v2/cookbook/using-axios-to-consume-apis.html
import axios from 'axios'

const apiPath = '/api/v1'

const http = axios.create({
  baseURL: apiPath,
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
})

// var tokens = document.getElementsByName('gorilla.csrf.Token')
// if (tokens.length > 0) {
//   http.defaults.headers['x-csrf-token'] = tokens[0].value
// }

export default http
