import http from './http'
const tasks = {
  getAll: (showCompleted) =>
    http.get(showCompleted ? `/tasks?showCompleted=1` : `/tasks`),
  getTaskList: () => http.get(`/tasks/tasklist`),
  getById: (id) => http.get(`/tasks/${id}`),
  save: (data) => http.post(`/tasks/${data.id}`, data),
  del: (id) => http.delete(`/tasks/${id}`),
  move: (taskid, data) => http.post(`/tasks/${taskid}/move`, data),

  getUsers: (id) => http.get(`/tasks/${id}/users`),
  getAllUsers: (id) => http.get(`/tasks/${id}/allusers`),
  getAllSpaceUsers: (id) => http.get(`/tasks/${id}/allspaceusers`),
  getHistory: (id) => http.get(`/tasks/${id}/history`),
  saveUsers: (id, data) => http.post(`/tasks/${id}/users`, data),

  getBoards: (id) => http.get(`/tasks/${id}/boards`),
  getStages: (id) => http.get(`/tasks/${id}/stages`),
  getProjects: (id) => http.get(`/tasks/${id}/projects`),

  getFiles: (id) => http.get(`/tasks/${id}/files`),
  uploadFiles: (id, data) =>
    http.post(`/tasks/${id}/upload`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }),
  postLink: (id, data) => http.post(`/tasks/${id}/link`, data),
  postFlag: (id, data) => http.post(`/tasks/${id}/setflag`, data),

  getMemos: (id) => http.get(`/tasks/${id}/memos`),
  saveMemo: (id, data) => http.post(`/tasks/${id}/memo`, data),

  getReminders: (id) => http.get(`/tasks/${id}/reminders`),
  getReminderById: (tid, id) => http.get(`/tasks/${tid}/reminders/${id}`),
  saveReminder: (id, rid, data) =>
    http.post(`/tasks/${id}/reminders/${rid}`, data)
}

export default tasks
