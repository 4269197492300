import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import task from './task'
import users from './users'
import project from './project'
import setts from './setts'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    task,
    users,
    project,
    setts
  }
})

export default store
