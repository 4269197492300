<template>
  <div>
    <div class="md:bg-gradient-to-r from-gray-100 to-blue-100 relative">
      <div
        class="
          container
          mx-auto
          flex flex-wrap flex-col
          md:flex-row
          items-center
        "
      >
        <!--Left Col-->
        <div
          class="
            flex flex-col
            w-full
            md:w-2/5
            justify-center
            items-center
            text-center
            md:px-8
          "
        >
          <p class="uppercase tracking-loose w-full">tender-flow.ru</p>
          <h1 class="my-4 text-5xl font-bold md:leading-tight">
            Управление задачами бизнеса
          </h1>
          <p class="leading-normal text-2xl mb-8">
            Здесь живут Ваши бизнес-задачи!
          </p>
          <div class="md:max-w-md mx-auto pt-8 z-50">
            <div ref="telegram" class="flex justify-center"></div>
          </div>
          <div class="text-xs text-gray-600 w-72 pt-12 z-50">
            * входя на сайт Вы соглашаетесь с условиями
            <a href="/user-agreement">Пользовательского соглашения </a>
          </div>
        </div>
        <!--Right Col-->
        <div class="w-full md:w-3/5 md:py-6 text-center">
          <BigLogo></BigLogo>
        </div>
      </div>
      <div class="absolute bottom-0 opacity-0 md:opacity-95 z-10">
        <Wave></Wave>
      </div>
    </div>

    <section class="bg-white border-b py-8">
      <div class="container mx-auto flex flex-wrap pt-4 pb-12">
        <h1
          class="
            w-full
            my-2
            text-5xl
            font-bold
            leading-tight
            text-center text-gray-800
          "
        >
          Доступ к системе
        </h1>
        <div class="w-full mb-4">
          <div
            class="
              h-1
              mx-auto
              bg-gradient-to-r
              from-gray-100
              to-blue-100
              w-64
              opacity-25
              my-0
              py-0
              rounded-t
            "
          ></div>
        </div>
        <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
          <div
            class="
              flex-1
              bg-white
              rounded-t rounded-b-none
              overflow-hidden
              shadow
            "
          >
            <a href="#" class="flex flex-wrap no-underline hover:no-underline">
              <p
                class="
                  w-full
                  text-gray-600 text-xs
                  md:text-sm
                  px-6
                  pt-6
                  uppercase
                "
              >
                Тесная интеграция с Telegram
              </p>
              <div class="w-full font-bold text-xl text-gray-800 p-6">
                Вход в систему возможен только при наличии у Вас аккаунта
                <a
                  href="https://telegram.org"
                  class="text-blue-900"
                  target="_blank"
                  >Telegram</a
                >.
              </div>
              <p class="text-gray-800 text-base px-6 mb-5">
                Мы <strong>не храним</strong> ваших персональных данных в нашем
                сервисе. Никто <strong>не сможет</strong> взломать Ваш аккаунт и
                украсть пароль или личную информацию. Безопасность защищена
                средствами Telegram.
              </p>
            </a>
          </div>
        </div>
        <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
          <div
            class="
              flex-1
              bg-white
              rounded-t rounded-b-none
              overflow-hidden
              shadow
            "
          >
            <a href="#" class="flex flex-wrap no-underline hover:no-underline">
              <p
                class="
                  w-full
                  text-gray-600 text-xs
                  md:text-sm
                  px-6
                  pt-6
                  uppercase
                "
              >
                Бот TenderFlow
              </p>
              <div class="w-full font-bold text-xl text-gray-800 p-6">
                Система будет уведомлять Вас о разных событиях с помощью
                <a
                  href="https://t.me/tender_flow_bot"
                  target="_blank"
                  class="text-blue-900"
                  >бота Telegram</a
                >.
              </div>
              <p class="text-gray-800 text-base px-6 mb-5">
                Когда Вы впервые будете входить в систему, оставьте включенной
                галочку
                <b>"Разрешить Tender Flow Bot писать Вам в Telegram"</b>, иначе
                Вы не будете получать уведомлений и не сможете полноценно
                пользоваться системой.
              </p>
            </a>
          </div>
        </div>
        <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
          <div
            class="
              flex-1
              bg-white
              rounded-t rounded-b-none
              overflow-hidden
              shadow
            "
          >
            <a href="#" class="flex flex-wrap no-underline hover:no-underline">
              <p
                class="
                  w-full
                  text-gray-600 text-xs
                  md:text-sm
                  px-6
                  pt-6
                  uppercase
                "
              >
                Разрешение на доступ к системе
              </p>
              <div class="w-full font-bold text-xl text-gray-800 p-6">
                Чтобы Вы смогли успешно войти в систему, администратор должен
                зарегистрировать Вас в системе и выдать права.
              </div>
              <p class="text-gray-800 text-base px-6 mb-5">
                Если вы логинитесь в систему, но ничего не происходит -
                обартитесь к своему администратору. Если это не помогло,
                напишите в
                <a
                  href="https://t.me/tsov_pro"
                  target="_blank"
                  class="text-blue-900"
                  >техподдержку</a
                >.
              </p>
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-gray-100 pt-8 pb-32 relative">
      <div class="container mx-auto px-2 pt-4 pb-12 text-gray-800">
        <h1
          class="
            w-full
            my-2
            text-5xl
            font-bold
            leading-tight
            text-center text-gray-800
          "
        >
          Стоимость услуг сервиса
        </h1>
        <div class="w-full mb-4">
          <div
            class="
              h-1
              mx-auto
              bg-gradient-to-r
              from-gray-100
              to-blue-100
              w-64
              opacity-25
              my-0
              py-0
              rounded-t
            "
          ></div>
        </div>
        <div
          class="flex flex-col sm:flex-row justify-center pt-12 my-12 sm:my-4"
        >
          <div
            class="
              flex flex-col
              w-5/6
              lg:w-1/4
              mx-auto
              lg:mx-0
              rounded-none
              lg:rounded-l-lg
              bg-white
              mt-4
            "
          >
            <div
              class="
                flex-1
                bg-white
                text-gray-600
                rounded-t rounded-b-none
                overflow-hidden
                shadow
              "
            >
              <div
                class="
                  p-8
                  text-3xl
                  font-bold
                  text-center
                  border-b-4
                  bg-gradient-to-b
                  from-white
                  to-purple-200
                "
              >
                Ознакомление
              </div>
              <ul class="w-full text-center text-sm">
                <li class="border-b py-4">1 окружение</li>
                <li class="border-b py-4">3 пользователя</li>
                <li class="border-b py-4">без хранения файлов</li>
                <li class="border-b py-4">∞ проекты</li>
                <li class="border-b py-4">∞ заказчики</li>
                <li class="border-b py-4">до 30 задач</li>
                <li
                  class="border-b py-4"
                  title="через месяц после создания задача будет удалена вместе со всем содержимым"
                >
                  задачи живут 1 месяц
                </li>
              </ul>
            </div>
            <div
              class="
                flex-none
                mt-auto
                bg-white
                rounded-b rounded-t-none
                overflow-hidden
                shadow
                p-6
              "
            >
              <div
                class="w-full pt-6 text-3xl text-gray-600 font-bold text-center"
              >
                0 р.
                <span class="text-base"></span>
              </div>
              <div class="flex items-center justify-center">
                <!-- <button
                  class="
                    mx-auto
                    lg:mx-0
                    hover:underline
                    bg-gradient-to-r
                    from-gray-100
                    to-blue-100
                    text-gray-800
                    font-bold
                    rounded-full
                    my-6
                    py-4
                    px-8
                    shadow-lg
                    focus:outline-none
                    focus:shadow-outline
                    transform
                    transition
                    hover:scale-105
                    duration-300
                    ease-in-out
                  "
                >
                  Попробовать
                </button> -->
              </div>
            </div>
          </div>
          <div
            class="
              flex flex-col
              w-5/6
              lg:w-1/3
              mx-auto
              lg:mx-0
              rounded-lg
              bg-white
              mt-4
              sm:-mt-6
              shadow-lg
              z-10
            "
          >
            <div
              class="
                flex-1
                bg-white
                rounded-t rounded-b-none
                overflow-hidden
                shadow
              "
            >
              <div
                class="
                  w-full
                  p-8
                  text-3xl
                  font-bold
                  text-center
                  bg-gradient-to-b
                  from-white
                  to-green-200
                "
              >
                Малый бизнес
              </div>
              <div
                class="
                  h-1
                  w-full
                  bg-gradient-to-r
                  from-gray-100
                  to-blue-100
                  my-0
                  py-0
                  rounded-t
                "
              ></div>
              <ul class="w-full text-center text-base font-bold">
                <li class="border-b py-4">∞ окружения</li>
                <li class="border-b py-4">∞ пользователи</li>
                <li class="border-b py-4">∞ файлы</li>
                <li class="border-b py-4">∞ проекты</li>
                <li class="border-b py-4">∞ заказчики</li>
                <li class="border-b py-4">∞ задачи</li>
                <li
                  class="border-b py-4"
                  title="через год после создания задача будет удалена вместе со всем содержимым"
                >
                  задачи живут 1 год
                </li>
                <li class="border-b py-4">нет доступа к API</li>
              </ul>
            </div>
            <div
              class="
                flex-none
                mt-auto
                bg-white
                rounded-b rounded-t-none
                overflow-hidden
                shadow
                p-6
              "
            >
              <div class="w-full pt-6 text-4xl font-bold text-center">
                1000 р.
                <span class="text-base">/ мес / чел</span>
              </div>
              <div class="flex items-center justify-center">
                <!-- <button
                  class="
                    mx-auto
                    lg:mx-0
                    hover:underline
                    bg-gradient-to-r
                    from-gray-100
                    to-blue-100
                    text-gray-800
                    font-bold
                    rounded-full
                    my-6
                    py-4
                    px-8
                    shadow-lg
                    focus:outline-none
                    focus:shadow-outline
                    transform
                    transition
                    hover:scale-105
                    duration-300
                    ease-in-out
                  "
                >
                  Оплатить
                </button> -->
              </div>
            </div>
          </div>
          <div
            class="
              flex flex-col
              w-5/6
              lg:w-1/4
              mx-auto
              lg:mx-0
              rounded-none
              lg:rounded-l-lg
              bg-white
              mt-4
            "
          >
            <div
              class="
                flex-1
                bg-white
                text-gray-600
                rounded-t rounded-b-none
                overflow-hidden
                shadow
              "
            >
              <div
                class="
                  p-8
                  text-3xl
                  font-bold
                  text-center
                  border-b-4
                  bg-gradient-to-b
                  from-white
                  to-blue-200
                "
              >
                КОРП
              </div>
              <ul class="w-full text-center text-sm">
                <li class="border-b py-4">∞ окружения</li>
                <li class="border-b py-4">∞ пользователи</li>
                <li class="border-b py-4">∞ файлы</li>
                <li class="border-b py-4">∞ проекты</li>
                <li class="border-b py-4">∞ заказчики</li>
                <li class="border-b py-4">∞ задачи</li>
                <li class="border-b py-4">Доступ к API</li>
              </ul>
            </div>
            <div
              class="
                flex-none
                mt-auto
                bg-white
                rounded-b rounded-t-none
                overflow-hidden
                shadow
                p-6
              "
            >
              <div
                class="w-full pt-6 text-3xl text-gray-600 font-bold text-center"
              >
                1500 р.
                <span class="text-base">/ мес / чел</span>
              </div>
              <div class="flex items-center justify-center">
                <!-- <button
                  class="
                    mx-auto
                    lg:mx-0
                    hover:underline
                    bg-gradient-to-r
                    from-gray-100
                    to-blue-100
                    text-gray-800
                    font-bold
                    rounded-full
                    my-6
                    py-4
                    px-8
                    shadow-lg
                    focus:outline-none
                    focus:shadow-outline
                    transform
                    transition
                    hover:scale-105
                    duration-300
                    ease-in-out
                  "
                >
                  Оплатить
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="absolute bottom-0 opacity-0 md:opacity-95">
        <Wave></Wave>
      </div>
    </section>

    <section class="flex flex-col mx-auto text-center py-6 mb-12 bg-white">
      <h1 class="w-full my-2 text-5xl font-bold leading-tight text-center">
        Остались вопросы?
      </h1>
      <div class="w-full mb-4">
        <div
          class="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"
        ></div>
      </div>
      <h3 class="my-4 text-3xl leading-tight">Пишите нам в телеграм!</h3>
      <a
        class="
          mx-auto
          hover:underline
          bg-white
          text-gray-800
          font-bold
          rounded-full
          py-4
          px-8
          shadow-lg
          focus:outline-none focus:shadow-outline
          transform
          transition
          hover:scale-105
          duration-300
          ease-in-out
        "
        href="https://t.me/tender_flow_bot"
        target="_blank"
      >
        Написать
      </a>
    </section>
    <div class="text-center mx-auto text-xs">
      © ООО «РЭМ-СОФТ», Москва, 2021, +7 (917) 549 63 97, info@rem-soft.ru
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BigLogo from './BigLogo.vue'
import Wave from './Wave.vue'
export default {
  components: {
    BigLogo,
    Wave
  },
  mounted() {
    const script = document.createElement('script')
    script.async = true
    script.src = 'https://telegram.org/js/telegram-widget.js?14'
    script.setAttribute('data-size', 'large')
    script.setAttribute('data-telegram-login', 'tender_flow_bot')
    script.setAttribute('data-request-access', 'write')

    window.onTelegramAuth = this.onTelegramAuth
    script.setAttribute('data-onauth', 'window.onTelegramAuth(user)')
    this.$refs.telegram.appendChild(script)
  },
  methods: {
    ...mapActions(['login']),
    onTelegramAuth(user) {
      this.login(user)
    }
  }
}
</script>

<style>
svg#freepik_stories-product-iteration:not(.animated) .animable {
  opacity: 0;
}
svg#freepik_stories-product-iteration.animated #freepik--Shadow--inject-4 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideRight;
  animation-delay: 0s;
}
svg#freepik_stories-product-iteration.animated #freepik--character-2--inject-4 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideLeft;
  animation-delay: 0s;
}
svg#freepik_stories-product-iteration.animated #freepik--Graphic--inject-4 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideDown;
  animation-delay: 0s;
}
svg#freepik_stories-product-iteration.animated #freepik--character-1--inject-4 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideUp;
  animation-delay: 0s;
}
@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: inherit;
  }
}
</style>
