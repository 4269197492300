import users from '@/api/users'
import { catchErr } from '@/error'

export default {
  state: {
    users: []
  },
  mutations: {
    setUsers(state, users) {
      state.users = users
    }
  },
  actions: {
    fetchAllUsers({ commit }) {
      users
        .getAll()
        .then((response) => {
          commit('setUsers', response.data)
        })
        .catch((error) => {
          commit('setUsers', [])
          catchErr(error)
        })
    }
  },
  getters: {
    selAllUsers(state) {
      var result = {}
      state.users.forEach((u) => {
        result[u.id] =
          u.fullname + ' (' + (u.username ? u.username : u.tgId) + ')'
      })
      return result
    }
  }
}
