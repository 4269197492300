<template>
  <div class="md:py-1 md:-mt-4 py-8 flex flex-col items-center">
    <div
      class="md:flex justify-start text-center md:pb-0 pb-2 space-x-4 w-full"
    >
      <div class="flex p-1 md:w-1/6 space-x-4">
        <label
          class="flex items-center cursor-pointer"
          @click.prevent="$router.push('/')"
        >
          <t-radio name="pageswitch" value="kanban" />
          <span class="ml-1 text-sm">Канбан</span>
        </label>
        <label class="flex items-center ml-2 cursor-pointer">
          <t-radio name="pageswitch" value="tasklist" checked />
          <span class="ml-1 text-sm">Список</span>
        </label>
      </div>
      <div class="md:flex justify-end w-full">
        <div class="p-1 md:w-1/6">
          <t-rich-select
            v-model="filterProjectId"
            :options="projectsCustomers"
            value-attribute="id"
            text-attribute="name"
            placeholder="Проекты..."
            noResultsText="Не найдено..."
            searchBoxPlaceholder="Найти..."
            clearable
          ></t-rich-select>
        </div>
        <div class="p-1 md:w-1/6">
          <t-rich-select
            v-model="filterStage"
            :options="selStages"
            placeholder="Этапы..."
            noResultsText="Не найдено..."
            searchBoxPlaceholder="Найти..."
            clearable
          ></t-rich-select>
        </div>
        <div class="p-1 md:w-1/6">
          <t-rich-select
            v-model="filterUser"
            :options="selAllUsers"
            placeholder="Назначено..."
            noResultsText="Не найдено..."
            searchBoxPlaceholder="Найти..."
            clearable
          ></t-rich-select>
        </div>
      </div>
    </div>
    <t-accordion-item
      v-for="(stage, indexStage) in allStages.filter((t) => {
        return t === filterStage || !filterStage
      })"
      v-bind:key="indexStage"
      class="
        border-b border-gray-200
        bg-gray-200
        pb-4
        text-gray-800
        md:w-3/4
        w-full
      "
      :title="stage"
      isActive="true"
      textCenter="true"
    >
      <div
        class="overflow-x-auto flex flex-col items-center"
        style="min-height: 8em"
      >
        <div
          v-for="(board, index) in allBoards"
          v-bind:key="index"
          class="flex-shrink-0 flex flex-col justify-items-stretch pb-2 w-full"
        >
          <t-accordion-item
            class="bg-gray-100 h-full"
            textCenter="false"
            :isActive="false"
            customClass="text-gray-800"
          >
            <template #header>
              <div
                class="
                  flex
                  justify-between
                  items-center
                  pl-6
                  pr-14
                  py-2
                  bg-gray-100
                  text-xs
                "
              >
                <h2 class="font-medium text-gray-800">
                  <t-tag tag-name="span" variant="smallbadge">{{
                    tasksByProjectBoardStage(
                      filterProjectId,
                      board,
                      stage,
                      filterUser
                    ).length
                  }}</t-tag>
                  {{ board }}
                </h2>
                <a
                  @click.prevent="newTaskModal(board, stage)"
                  href="#"
                  class="
                    inline-flex
                    items-center
                    font-medium
                    text-gray-500
                    hover:text-blue-600
                  "
                >
                  <svg
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                  Задача
                </a>
              </div>
            </template>

            <div class="flex flex-col justify-items-stretch rounded-lg">
              <div
                v-for="t in tasksByProjectBoardStage(
                  filterProjectId,
                  board,
                  stage,
                  filterUser
                )"
                v-bind:key="t.id"
                :ref="t.id"
                class="bg-white p-3"
                @dblclick="openEditTask(t)"
              >
                <div class="flex flex-row items-center justify-start space-x-2">
                  <div v-if="t.assigned.fullname">
                    <img
                      v-if="t.assigned.photo"
                      class="
                        h-10
                        w-10
                        rounded-full
                        ring-2 ring-white
                        hover:shadow-lg
                      "
                      v-bind:src="t.assigned.photo"
                      alt=""
                    />
                    <t-avatar
                      v-else
                      class="h-10 w-10 hover:shadow-lg"
                      :username="t.assigned.fullname"
                    ></t-avatar>
                  </div>
                  <div
                    class="flex flex-col items-start justify-start space-y-1"
                  >
                    <div class="flex flex-row justify-start space-x-2">
                      <t-select-flag
                        class="text-sm"
                        v-bind:value="t.currentUserFlag"
                        v-on:input="setFlag(t, $event)"
                      ></t-select-flag>
                      <a
                        v-text="t.name"
                        class="
                          text-gray-800
                          no-underline
                          hover:underline
                          cursor-pointer
                          flex
                          text-sm
                        "
                        herf="#"
                        @click="openEditTask(t)"
                      ></a>
                    </div>

                    <div
                      class="
                        text-gray-800 text-xs
                        flex flex-wrap
                        justify-start
                        items-center
                        space-x-2
                      "
                    >
                      <div>#{{ t.number }}</div>
                      <div v-text="formatDateDisplay(t.endDate)"></div>

                      <div>
                        <span
                          v-if="t.assignedId == currentUser.id"
                          class="pr-1"
                          :class="
                            taskExpired(t) ? 'text-red-400' : 'text-green-400'
                          "
                          >&bull;</span
                        >{{ t.assigned.fullname }}
                      </div>
                      <div>{{ t.project.customer.name }}</div>
                      <div>{{ t.project.name }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-4"></div>
            </div>
          </t-accordion-item>
        </div>
      </div>
    </t-accordion-item>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { generateUUID } from './const'
import tasks from '@/api/tasks'
import { catchErr } from '@/error'

export default {
  mounted() {
    this.fetchData()
  },

  watch: {
    // при изменениях маршрута запрашиваем данные снова
    $route: 'loadTasksData'
  },

  data() {
    return {
      filterProjectId: null,
      filterStage: null,
      filterUser: null
    }
  },

  computed: {
    ...mapGetters([
      'currentUser',
      'allBoards',
      'allStages',
      'projectsCustomers',
      'selStages',
      'allTasks',
      'selAllUsers',
      'isAdmin'
    ])
  },

  methods: {
    ...mapActions([
      'fetchTaskClassifiers',
      'fetchAllProjects',
      'fetchAllTasks',
      'fetchAllUsers',
      'moveTaskStageBoard'
    ]),

    fetchData() {
      this.fetchTaskClassifiers()
      if (this.isAdmin) {
        this.fetchAllUsers()
      }
      this.loadTasksData()
    },

    loadTasksData() {
      this.fetchAllProjects()
      this.fetchAllTasks()
    },

    tasksByProjectBoardStage(filterProjectId, board, stage, filterUser) {
      return this.allTasks.filter((t) => {
        return (
          t.boardId === board &&
          t.stageId === stage &&
          (t.projectId === filterProjectId || !filterProjectId) &&
          (t.assignedId === filterUser || !filterUser)
        )
      })
    },

    newTaskModal(board, stage) {
      // Новая задача
      this.$router.push({
        path: `/task/${generateUUID()}`,
        query: {
          boardId: board,
          stageId: stage,
          projectId: this.filterProjectId ? this.filterProjectId : ''
        }
      })
    },

    openEditTask(t) {
      this.$router.push({
        path: `/task/${t.id}`
      })
    },

    taskExpired(t) {
      if (t.endDate) {
        let end = new Date(t.endDate)
        end.setHours(23, 59, 59, 999)
        return end < new Date()
      }
      return false
    },

    formatDateDisplay(date) {
      if (!date) {
        return 'когда нибудь'
      }
      let options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }
      let d = new Date(date)
      return d.toLocaleDateString('ru-RU', options)
    },

    setFlag(t, v) {
      tasks
        .postFlag(t.id, { flag: v })
        .then(() => {
          t.currentUserFlag = v
        })
        .catch((error) => {
          if (error.response.status != 404) {
            catchErr(error)
          }
        })
    }
  }
}
</script>
