<template>
  <div class="md:max-w-5xl mx-auto w-full h-full">
    <t-card>
      <div class="flex-1 w-full md:rounded-lg bg-white min-h-full space-y-2">
        <t-rich-select
          v-model="selected"
          :options="allBoards"
          value-attribute="id"
          text-attribute="name"
          placeholder="Статусы..."
          noResultsText="Не найдено..."
          searchBoxPlaceholder="Найти..."
          ref="statusEditor"
        >
          <template slot="dropdownDown">
            <div class="text-center">
              <button
                type="button"
                class="
                  block
                  w-full
                  px-3
                  py-2
                  text-white
                  bg-blue-700
                  border
                  hover:bg-blue-800
                  md:rounded
                "
                @click="createOption()"
              >
                Создать новый
              </button>
            </div>
          </template>
        </t-rich-select>
        <div v-show="editing" class="flex flex-col space-y-2 ml-4">
          <t-input-group label="Наименование">
            <t-input placeholder="Наименование..." v-model="current.id" />
          </t-input-group>

          <t-input-group label="Очередность" class="md:w-1/5">
            <t-input
              v-model="current.position"
              placeholder="Номер по порядку..."
              type="number"
            />
          </t-input-group>

          <t-input-group
            label="Бэклог"
            class="flex flex-row items-center space-x-2"
          >
            <t-toggle v-model="current.backlog" />
          </t-input-group>

          <t-input-group
            label="Завершение задач"
            class="flex flex-row items-center space-x-2"
          >
            <t-toggle v-model="current.completion" />
          </t-input-group>

          <t-input-group
            label="Скрыть из списка"
            class="flex flex-row items-center space-x-2"
          >
            <t-toggle v-model="current.hided" />
          </t-input-group>

          <div class="flex justify-end items-center space-x-2">
            <t-button variant="secondary" @click="cancelCurrent"
              >Отмена</t-button
            >
            <t-button @click="saveBoard">Сохранить</t-button>
          </div>
        </div>
      </div>
    </t-card>
  </div>
</template>

<script>
import boards from '@/api/boards'
import { catchErr } from '@/error'
import { eventBus } from '@/eventBus'

export default {
  data() {
    return {
      selected: null,
      allBoards: [],
      current: {},
      lastSelectedId: null,
      editing: false
    }
  },
  created() {
    this.fetchData()
    eventBus.$on('changeSpace', this.fetchData)
  },
  beforeDestroy() {
    eventBus.$off('changeSpace')
  },
  watch: {
    selected: 'loadCurrent'
  },
  methods: {
    fetchData() {
      boards
        .getAll()
        .then((resp) => {
          this.setAllBoards(resp.data)
          this.current = {}
          this.selected = null
          this.lastSelectedId = null
          this.editing = false
        })
        .catch((error) => {
          catchErr(error)
        })
    },
    setAllBoards(data) {
      let items = data
      items.forEach((item) => {
        item.name =
          item.id + ' (' + item.position + (item.hided ? ', скрыто' : '') + ')'
      })
      this.allBoards = items
    },
    cancelCurrent() {
      this.current = {}
      this.selected = null
      this.lastSelectedId = null
      this.editing = false
    },
    loadCurrent() {
      if (this.selected) {
        boards
          .getById(this.selected)
          .then((resp) => {
            this.current = resp.data
            this.selected = this.current.id
            this.lastSelectedId = this.current.id
            this.editing = true
          })
          .catch((error) => {
            if (error.response.status == 404) {
              this.current = {
                id: '',
                position: 0,
                backlog: false,
                completion: false,
                hided: false
              }
              this.selected = this.current.id
              this.lastSelectedId = this.current.id
              this.editing = true
            } else {
              catchErr(error)
            }
          })
      } else {
        this.current = {
          id: '',
          position: 0,
          backlog: false,
          completion: false,
          hided: false
        }
        this.selected = this.current.id
        this.lastSelectedId = this.current.id
        // this.editing = true
      }
    },
    createOption() {
      this.current = {
        id: '',
        position: 0,
        backlog: false,
        completion: false,
        hided: false
      }
      this.selected = this.current.id
      this.lastSelectedId = this.current.id
      this.editing = true
      this.$refs.statusEditor.hideOptions()
    },

    saveBoard() {
      let saveData = {}
      for (let k in this.current) saveData[k] = this.current[k]
      saveData.position = Number(saveData.position)

      boards
        .save(this.lastSelectedId, saveData)
        .then((resp) => {
          this.current = resp.data
          this.editing = false
          boards
            .getAll()
            .then((resp) => {
              this.setAllBoards(resp.data)
              this.selected = this.current.id
              this.lastSelectedId = this.current.id
              this.$busEmit('flash', 'Статус сохранен')
            })
            .catch((error) => {
              catchErr(error)
            })
        })
        .catch((error) => {
          catchErr(error)
        })
    }
  }
}
</script>
