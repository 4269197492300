<template>
  <div class="bg-white shadow flex flex-col sm:rounded-sm">
    <div class="px-4 py-5 sm:px-6 relative">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        {{ loading ? 'Загрузка данных...' : title }}
      </h3>
      <div
        class="
          modal-close
          absolute
          top-0
          right-0
          cursor-pointer
          flex flex-col
          items-center
          mt-4
          mr-4
          text-sm
          z-50
          text-gray-500
        "
        @click="$emit('closeForm')"
      >
        <svg
          class="fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          ></path>
        </svg>
      </div>
      <p class="mt-1 max-w-2xl text-sm text-gray-500" v-if="description">
        {{ description }}
      </p>
      <slot name="header" v-if="!loading"></slot>
    </div>
    <Loading v-if="loading"></Loading>
    <div class="flex flex-col" v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Loading from './Loading.vue'
export default {
  props: ['title', 'description', 'loading'],
  components: { Loading }
}
</script>
