<template>
  <div class="accordion-item" :class="{ 'is-active': active }">
    <div class="relative">
      <button
        @click="toggle"
        class="w-full bg-transparent border-transparent font-medium"
        :class="
          ' ' +
          (textCenter ? 'text-center' : 'text-left') +
          ' ' +
          (customClass ? customClass : 'py-3 px-5 text-gray-800') +
          ' ' +
          (active ? 'py-6' : '')
        "
      >
        <h3>
          <i :class="icon" v-if="icon" class="mr-2"></i>
          {{ title }}
        </h3>
        <slot name="header"></slot>
        <span
          class="
            absolute
            top-0
            bottom-0
            block
            m-auto
            border-b-2 border-r-2 border-gray-800
            accordion-item-trigger-icon
          "
          :class="{ 'is-active': active }"
        ></span>
      </button>
    </div>
    <transition
      name="accordion-item"
      @enter="startTransition"
      @after-enter="endTransition"
      @before-leave="startTransition"
      @after-leave="endTransition"
    >
      <div v-show="active">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ['isActive', 'title', 'textCenter', 'customClass', 'icon'],
  data() {
    return {
      active: this.isActive
    }
  },
  watch: {
    isActive: 'refreshActive'
  },
  methods: {
    toggle() {
      this.active = !this.active
      this.$emit('update:isActive', this.active)
    },
    startTransition(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    endTransition(el) {
      el.style.height = ''
    },
    refreshActive() {
      this.active = this.isActive
    }
  }
}
</script>

<style lang="scss">
.accordion-item-trigger-icon {
  $size: 8px;
  right: 1.25rem;
  width: $size;
  height: $size;
  transform: translateY(-$size / 4) rotate(45deg);
  transition: transform 0.2s ease;

  &.is-active {
    transform: translateY($size / 4) rotate(225deg);
  }
}

.accordion-item-enter-active,
.accordion-item-leave-active {
  will-change: height;
  transition: height 0.2s ease;
  overflow: hidden;
}
.accordion-item-enter,
.accordion-item-leave-to {
  height: 0 !important;
}
</style>
