<template>
  <div
    class="
      flex
      md:flex-row
      flex-col
      justify-between
      items-center
      mb-2
      md:pb-4 md:space-y-0
      space-y-2
    "
  >
    <div
      class="
        flex
        md:justify-start md:flex-row
        flex-col
        justify-center
        items-center
        space-x-4
      "
    >
      <a
        @click.prevent="$router.push('/')"
        href="#"
        class="inline-flex items-center text-blue-400"
      >
        <Logo></Logo>
      </a>
      <div class="flex md:items-start items-center flex-col space-y-2">
        <h1
          class="
            text-xl
            md:text-2xl
            text-gray-800
            font-semibold
            md:text-left
            text-center
          "
          v-text="greetText()"
        ></h1>
        <div
          v-text="formatDateDisplay(new Date())"
          class="text-sm text-gray-600"
        ></div>
      </div>
    </div>

    <div
      class="
        flex
        justify-end
        items-center
        space-x-2
        md:space-y-0
        space-y-2
        md:flex-row
        flex-col
      "
    >
      <div
        class="
          ml-4
          text-xs
          inline-flex
          flex-col
          items-center
          leading-sm
          uppercase
          px-3
          py-1
          rounded-full
          bg-white
          text-gray-700
          border
        "
        title="Текущее окружение"
      >
        <a href="#" @click.prevent.stop="showTarifDetails = true">
          <p
            v-if="currentUser.currentSpaceState.status != 0"
            class="text-red-700"
          >
            Заблокировано
          </p>

          {{ currentUser.currentSpaceState.spaceName }}
        </a>

        <!-- TODO: add space -->
      </div>

      <div class="flex flex-row items-center space-x-2">
        <a
          @click.prevent="$router.push('/settings')"
          href="#"
          class="rounded-lg font-medium inline-flex items-center"
          title="Настройки"
        >
          <img
            v-if="currentUser.photo"
            class="h-10 w-10 rounded-full ring-2 ring-white hover:shadow-lg"
            v-bind:src="currentUser.photo"
            alt=""
          />
          <t-avatar
            v-else
            class="h-10 w-10 hover:shadow-lg"
            :username="currentUser.fullname"
          ></t-avatar>
        </a>
        <a
          @click.prevent="$router.push('/help')"
          href="#"
          class="rounded-lg font-medium inline-flex items-center pl-2"
          title="Справка"
        >
          <svg
            class="w-5 h-5 mr-1 text-blue-500 hover:text-blue-700"
            fill="none"
            viewBox="0 0 512 512"
            stroke="currentColor"
          >
            <path
              fill="currentColor"
              d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
            />
          </svg>
        </a>
      </div>
    </div>

    <t-modal v-model="showTarifDetails">
      <SpaceState></SpaceState>
    </t-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from '@/components/Logo.vue'
import SpaceState from './SpaceState'
export default {
  components: {
    Logo,
    SpaceState
  },
  computed: mapGetters(['currentUser']),
  data() {
    return {
      showTarifDetails: false
    }
  },
  methods: {
    greetText() {
      var d = new Date()
      var time = d.getHours()

      if (time < 12) {
        return 'Доброе утро, ' + this.currentUser.fullname
      } else if (time < 17) {
        return 'Добрый день, ' + this.currentUser.fullname
      } else {
        return 'Добрый вечер, ' + this.currentUser.fullname
      }
    },
    formatDateDisplay(date) {
      let options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }
      let d = new Date(date)
      // if (this.dateDisplay === "toDateString") return d.toLocaleDateString();
      // if (this.dateDisplay === "toLocaleDateString")
      return d.toLocaleDateString('ru-RU', options)
      // d = new Date();
      // return d.toLocaleDateString("ru-RU", options);
    }
  }
}
</script>
