<template>
  <div
    class="flex h-full w-full items-end md:items-center justify-center z-10"
    @keydown.esc="$router.push(prevRoute ? prevRoute.path : '/')"
  >
    <div class="md:p-4 md:max-w-5xl mx-auto w-full h-full flex-1 relative">
      <t-form
        :title="task.name ? task.name : 'Новая задача'"
        @closeForm="$router.push(prevRoute ? prevRoute.path : '/')"
        :loading="loading"
      >
        <template #header>
          <div class="flex flex-col md:flex-row">
            <div class="flex-1">
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                <span v-if="taskNumber">#{{ task.number }}</span>
                <span v-if="selAllProjects[task.projectId]">
                  {{ selAllProjects[task.projectId] }}
                </span>
              </p>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                {{ task.stageId }}, {{ task.boardId }}
              </p>

              <table
                class="
                  table-auto
                  mt-1
                  max-w-2xl
                  text-xs
                  md:text-sm
                  text-gray-500
                "
              >
                <tr v-if="task.startDate">
                  <td class="pr-2 md:pr-4">Начало</td>
                  <td class="pr-2 font-medium text-gray-800">
                    {{ startedTask }}
                  </td>
                  <td>{{ startedFromNow }}</td>
                </tr>
                <tr v-if="task.endDate">
                  <td class="pr-2 md:pr-4">Крайний срок</td>
                  <td class="pr-2 font-medium text-gray-800">
                    {{ endedTask }}
                  </td>
                  <td>{{ endedFromNow }}</td>
                </tr>
              </table>

              <p
                class="mt-2 max-w-2xl text-sm text-gray-500"
                v-show="taskAllSpaceUsers[task.assignedId]"
              >
                Назначена:
                <span class="font-medium text-gray-800">{{
                  taskAllSpaceUsers[task.assignedId]
                }}</span>
              </p>
              <p
                class="mt-2 max-w-2xl text-sm text-gray-500"
                v-if="
                  task.space &&
                  currentUser.currentSpaceState.spaceName != task.space
                "
              >
                Окружение:
                <span
                  class="
                    ml-4
                    text-xs
                    inline-flex
                    items-center
                    leading-sm
                    uppercase
                    px-3
                    py-1
                    rounded-full
                    bg-white
                    text-gray-700
                    border
                  "
                  v-text="task.space"
                >
                </span>
              </p>
              <p
                v-if="task.updatedAt"
                class="mt-1 max-w-2xl text-xs text-gray-500"
              >
                Обновлено
                {{ formatDateDisplay(task.updatedAt) }}
              </p>
            </div>
            <div class="flex-1">
              <p class="mt-2 max-w-2xl text-sm text-gray-800">
                {{ task.description }}
              </p>
            </div>
          </div>
        </template>

        <div class="md:p-6">
          <t-accordion-item
            ref="info"
            title="Постановка задачи"
            :isActive="exists === false"
            class="border mb-2 mx-1"
            icon="far fa-edit"
          >
            <t-line header="Название" gray>
              <t-input v-model="task.name" ref="taskName" required />
            </t-line>

            <t-line header="Описание">
              <div>
                <t-textarea
                  v-model="task.description"
                  type="text"
                  rows="8"
                  @paste="pasteImg"
                />
                <img
                  v-if="task.screenshot"
                  :src="task.screenshot"
                  class="border mt-2 cursor-pointer"
                  @click="openImg"
                />
              </div>
            </t-line>

            <t-line header="Начало" gray>
              <div class="flex flex-col items-center justify-start">
                <t-datetime
                  v-model="task.startDate"
                  label="Начало..."
                  format="YYYY-MM-DD HH:mm"
                  formatted="dddd LL"
                  only-date
                  auto-close
                  no-label
                  no-header
                  no-button-now
                  no-button
                  no-keyboard
                ></t-datetime>
                <div
                  v-text="startedFromNow"
                  v-show="this.task.startDate"
                  class="text-xs"
                ></div>
              </div>
            </t-line>

            <t-line header="Крайний срок">
              <div class="flex flex-col items-center justify-start">
                <t-datetime
                  v-model="task.endDate"
                  label="Окончание..."
                  format="YYYY-MM-DD HH:mm"
                  formatted="dddd LL"
                  only-date
                  auto-close
                  no-label
                  no-header
                  no-button-now
                  no-button
                  no-keyboard
                ></t-datetime>
                <div
                  v-text="endedFromNow"
                  v-show="this.task.endDate"
                  class="text-xs"
                ></div>
              </div>
            </t-line>
          </t-accordion-item>

          <t-accordion-item
            ref="users"
            title="Статус и участники"
            class="border mb-2 mx-1"
            icon="far fa-user"
          >
            <t-line header="Этап" gray>
              <div
                class="
                  flex
                  md:flex-row
                  flex-col
                  items-center
                  justify-start
                  space-x-2 space-y-2
                  md:space-y-0
                "
              >
                <t-rich-select
                  v-model="task.stageId"
                  :options="taskStages"
                  placeholder="Выберите этап..."
                  noResultsText="Не найдено..."
                  searchBoxPlaceholder="Найти..."
                  clearable
                  :title="'Этап проекта: ' + task.stageId"
                ></t-rich-select>
                <t-rich-select
                  v-model="task.boardId"
                  :options="taskBoards"
                  placeholder="Выберите статус..."
                  noResultsText="Не найдено..."
                  searchBoxPlaceholder="Найти..."
                  :title="'Статус: ' + task.boardId"
                ></t-rich-select>
              </div>
            </t-line>

            <t-line header="Назначена исполнителю">
              <div class="md:flex flex-row space-x-2">
                <t-rich-select
                  v-model="task.assignedId"
                  :options="taskAllUsers"
                  placeholder="Исполнитель не выбран..."
                  noResultsText="Не найдено..."
                  searchBoxPlaceholder="Найти..."
                  clearable
                ></t-rich-select>
                <t-button
                  variant="secondary"
                  @click="task.assignedId = currentUser.id"
                  >Назначить себя</t-button
                >
              </div>
            </t-line>

            <t-line gray v-if="isAdmin" header="Группа доступа к задаче">
              <template>
                <t-rich-select
                  v-model="taskUsers"
                  :options="taskAllSpaceUsers"
                  multiple
                  :close-on-select="false"
                  placeholder="Пользователи проекта..."
                  noResultsText="Не найдено..."
                  searchBoxPlaceholder="Найти..."
                ></t-rich-select>
                <div
                  v-if="!(taskUsers && taskUsers.length)"
                  class="flex flex-row space-x-2 text-xs text-gray-600"
                >
                  <span>Сейчас есть доступ:</span>
                  <div v-for="(user, k) in taskAllUsers" v-bind:key="k">
                    {{ user }}
                  </div>
                </div>
              </template>
            </t-line>

            <t-line
              gray
              v-else
              header="Участники"
              class="flex flex-col items-start"
            >
              <div
                v-if="taskUsers && taskUsers.length"
                class="flex flex-col items-start"
              >
                <div
                  v-for="(userid, idx) in taskUsers"
                  v-bind:key="idx"
                  class="md:mb-4 mb-2"
                >
                  {{ taskAllSpaceUsers[userid] }}
                </div>
              </div>
              <div v-else class="flex flex-col items-start">
                <div
                  v-for="(user, k) in taskAllUsers"
                  v-bind:key="k"
                  class="md:mb-4 mb-2"
                >
                  {{ user }}
                </div>
              </div>
            </t-line>

            <t-line header="Проект">
              <div v-if="isAdmin">
                <t-rich-select
                  v-model="task.projectId"
                  :options="projectsCustomers"
                  value-attribute="id"
                  text-attribute="name"
                  placeholder="Выберите проект..."
                  noResultsText="Не найдено..."
                  searchBoxPlaceholder="Найти..."
                  ref="projectSelect"
                >
                  <template slot="dropdownDown">
                    <div class="text-center">
                      <button
                        type="button"
                        class="
                          block
                          w-full
                          px-3
                          py-2
                          text-white
                          bg-blue-700
                          border
                          hover:bg-blue-800
                          md:rounded
                        "
                        @click="createProjectOption()"
                      >
                        Создать новый
                      </button>
                    </div>
                  </template>
                </t-rich-select>
                <div
                  v-show="projectEditing"
                  class="flex flex-col space-y-2 ml-4 my-2"
                >
                  <t-input-group label="Наименование">
                    <t-input
                      placeholder="Наименование..."
                      v-model="newProject.name"
                    />
                  </t-input-group>

                  <t-input-group label="Описание">
                    <t-textarea
                      v-model="newProject.description"
                      placeholder="Описание..."
                    />
                  </t-input-group>

                  <t-input-group label="Заказчик">
                    <div>
                      <t-rich-select
                        v-model="newProject.customerId"
                        :options="selAllCustomers"
                        placeholder="Заказчик..."
                        noResultsText="Не найдено..."
                        searchBoxPlaceholder="Найти..."
                        ref="customerSelect"
                      >
                        <template slot="dropdownDown">
                          <div class="text-center">
                            <button
                              type="button"
                              class="
                                block
                                w-full
                                px-3
                                py-2
                                text-white
                                bg-blue-700
                                border
                                hover:bg-blue-800
                                md:rounded
                              "
                              @click="createCustomerOption()"
                            >
                              Создать нового
                            </button>
                          </div>
                        </template>
                      </t-rich-select>

                      <div
                        v-show="customerEditing"
                        class="flex flex-col space-y-2 ml-4 my-2"
                      >
                        <t-input-group label="Наименование">
                          <t-input
                            placeholder="Наименование..."
                            v-model="newCustomer.name"
                          />
                        </t-input-group>
                        <t-input-group label="Описание">
                          <t-textarea
                            v-model="newCustomer.description"
                            placeholder="Описание..."
                          />
                        </t-input-group>
                        <div class="flex justify-end items-center space-x-2">
                          <t-button variant="secondary" @click="cancelCustomer"
                            >Отмена</t-button
                          >
                          <t-button @click="saveCustomer">Сохранить</t-button>
                        </div>
                      </div>
                    </div>
                  </t-input-group>

                  <t-input-group label="Пользователи">
                    <template>
                      <t-rich-select
                        v-model="newProjectUsers"
                        :options="selAllUsers"
                        multiple
                        :close-on-select="false"
                        placeholder="Пользователи проекта..."
                        noResultsText="Не найдено..."
                        searchBoxPlaceholder="Найти..."
                      ></t-rich-select>
                    </template>
                  </t-input-group>

                  <div class="flex justify-end items-center space-x-2">
                    <t-button variant="secondary" @click="cancelProject"
                      >Отмена</t-button
                    >
                    <t-button @click="saveProject">Сохранить</t-button>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="md:flex justify-between items-center md:mb-4 mb-2"
              >
                <div>
                  {{ selAllProjects[task.projectId] }}
                </div>
              </div>
            </t-line>
          </t-accordion-item>

          <t-accordion-item
            ref="files"
            title="Файлы"
            class="border mb-2 mx-1"
            icon="far fa-folder-open"
          >
            <TaskFiles :taskid="$route.params.id"> </TaskFiles>
          </t-accordion-item>

          <t-accordion-item
            ref="comments"
            title="Комментарии"
            class="border mb-2 mx-1"
            icon="far fa-comments"
          >
            <t-line gray noHeader v-show="taskMemos.length">
              <div
                v-for="comment in taskMemos"
                v-bind:key="comment.id"
                class="
                  flex flex-row
                  items-center
                  space-x-2
                  mb-2
                  text-sm
                  font-sans
                  rounded-lg
                  p-2
                "
                :class="{
                  'bg-blue-100 mr-0 ml-16':
                    comment.user.fullname == currentUser.fullname,
                  'bg-gray-200 mr-16 ml-0':
                    comment.user.fullname != currentUser.fullname
                }"
              >
                <img
                  v-if="comment.user.photo"
                  class="
                    h-10
                    w-10
                    rounded-full
                    ring-2 ring-white
                    hover:shadow-lg
                  "
                  v-bind:src="comment.user.photo"
                  alt=""
                />
                <t-avatar
                  v-else
                  class="h-10 w-10 hover:shadow-lg"
                  :username="comment.user.fullname"
                ></t-avatar>
                <div class="flex flex-col space-y-1">
                  <div class="text-xs font-medium text-gray-500">
                    {{ comment.user.fullname }}
                  </div>
                  <p class="whitespace-pre-line md:whitespace-pre-wrap">
                    {{ comment.comment }}
                  </p>
                  <div class="text-xs text-gray-500">
                    {{ momentFromNow(comment.updatedAt) }}
                  </div>
                </div>
              </div>
            </t-line>
            <t-line noHeader>
              <div class="flex flex-row justify-between py-2">
                <t-textarea
                  v-model="taskComment"
                  type="text"
                  class="h-24"
                  placeholder="Ваш комментарий..."
                />
              </div>
              <div class="flex justify-end items-center space-x-2">
                <t-button @click="sendComment" variant="secondary"
                  >Отправить</t-button
                >
              </div>
            </t-line>
          </t-accordion-item>

          <t-accordion-item
            ref="reminders"
            title="Напоминания"
            class="border mb-2 mx-1"
            icon="far fa-bell"
          >
            <t-line gray noHeader>
              <div
                v-for="reminder in taskReminders"
                v-bind:key="reminder.id"
                class="
                  md:flex
                  justify-between
                  items-center
                  space-x-1
                  md:mb-4
                  mb-2
                "
              >
                <div class="text-sm">
                  {{ reminder.user.fullname }}
                </div>
                <div class="text-sm">
                  <button
                    class="border-0 bg-none hover:underline"
                    @click="loadCurrentReminder(reminder.id)"
                  >
                    {{ reminder.text }}
                  </button>
                </div>
                <div class="text-sm">
                  <button
                    class="border-0 bg-none hover:underline"
                    @click="loadCurrentReminder(reminder.id)"
                  >
                    {{ formatDateTimeDisplay(reminder.raiseAt) }}
                  </button>
                </div>
                <div class="text-sm">
                  {{ reminder.receiverName }}
                </div>
                <div class="text-sm">
                  {{ reminder.raised ? '✓' : '' }}
                </div>
              </div>
            </t-line>
            <t-line noHeader>
              <div
                class="flex flex-row justify-center py-2"
                v-if="!selectedReminderId"
              >
                <t-button @click="addReminder" variant="secondary"
                  >Добавить</t-button
                >
              </div>
              <div v-if="selectedReminderId">
                <label class="text-gray-800 block mb-1 font-bold text-sm"
                  >Напоминание
                  <span v-show="!currentReminder.createdAt"
                    >&nbsp; (новое)</span
                  ></label
                >
                <t-input
                  placeholder="Текст напоминания..."
                  v-model="currentReminder.text"
                  class="pt-1"
                />

                <t-datetime
                  v-model="currentReminder.raiseAt"
                  format="YYYY-MM-DD HH:mm"
                  no-label
                  no-header
                  no-button-now
                  no-button
                  no-keyboard
                  inline
                  class="py-2"
                ></t-datetime>

                <t-rich-select
                  v-model="currentReminder.receiver"
                  :options="selReceivers"
                  placeholder="Группа получателей..."
                  noResultsText="Не найдено..."
                  searchBoxPlaceholder="Найти..."
                  class="pt-1"
                ></t-rich-select>

                <div class="flex justify-end pt-2">
                  <div class="pr-1">
                    <t-button
                      variant="secondary"
                      @click="selectedReminderId = null"
                      >Отмена</t-button
                    >
                  </div>
                  <div>
                    <t-button @click="saveCurrentReminder">Сохранить</t-button>
                  </div>
                </div>
              </div>
            </t-line>
          </t-accordion-item>

          <t-accordion-item
            ref="invitations"
            title="Встречи"
            class="border mb-2 mx-1"
            icon="far fa-calendar-alt"
          >
            <t-line gray header="Календарь задачи">
              <div
                class="
                  flex flex-col
                  justify-center
                  items-center
                  md:p-4
                  p-2
                  space-y-2
                "
              >
                <a
                  v-if="task.calendarSrc"
                  :href="task.calendarSrc"
                  target="_blank"
                  class="underline pb-2"
                  >Открыть календарь задачи</a
                >
                <div v-else>Календарь еще не назначен администратором</div>
                <t-input
                  v-if="isAdmin"
                  v-model="task.calendarSrc"
                  placeholder="Ссылка на общедоступный Google Calendar"
                ></t-input>
              </div>
            </t-line>
          </t-accordion-item>

          <t-accordion-item
            ref="history"
            title="История изменений"
            class="border mb-2 mx-1"
            icon="far fa-lightbulb"
            v-on:update:isActive="if ($event) fetchHistory()"
          >
            <div class="min-w-full text-xs flex flex-col px-4">
              <div
                class="
                  flex-wrap flex-row flex
                  items-center
                  justify-start
                  space-x-4
                  pb-2
                "
                v-for="thr in taskHistory"
                v-bind:key="thr.id"
              >
                <div>{{ formatDateTimeDisplay(thr.createdAt) }}</div>
                <div>{{ thr.user.fullname }}</div>
                <div class="flex flex-col flex-grow">
                  <div
                    v-for="(tch, key) in thr.changes"
                    v-bind:key="key"
                    class="
                      flex flex-row
                      items-center
                      justify-items-start
                      space-x-2
                    "
                  >
                    <div>{{ fieldNames[key] }}:</div>
                    <div>{{ valForKey(key, tch) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </t-accordion-item>
        </div>
      </t-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { emptyTask } from './const'
import tasks from '@/api/tasks'
import TaskFiles from './TaskFiles'
import { generateUUID } from './const'
import projects from '@/api/projects'
import customers from '@/api/customers'
import moment from 'moment'
import { catchErr } from '@/error'
import debounce from 'lodash.debounce'

export default {
  components: {
    TaskFiles
  },
  data() {
    return {
      prevRoute: null,
      loading: false,
      // fetching: true,
      task: emptyTask('', '', ''),
      exists: null,
      taskBoards: [],
      taskStages: [],
      taskProjects: [],
      taskComment: '',
      taskUsers: [],
      taskAllUsers: {},
      taskAllSpaceUsers: {},
      taskMemos: [],
      taskReminders: [],
      taskHistory: [],
      newReminder: {},
      taskNumber: 0,
      selectedReminderId: null,
      currentReminder: {
        id: null,
        taskId: null,
        text: '',
        receiver: 0,
        receiverName: '',
        raiseAt: new Date(),
        raised: false,
        userId: null,
        user: {}
      },
      selReceivers: [
        { value: 0, text: 'Исполнителю' },
        { value: 1, text: 'Всем' },
        { value: 2, text: 'Администратору' }
      ],
      fieldNames: {
        name: 'Название',
        description: 'Описание',
        date: 'Дата',
        startDate: 'Начало',
        endDate: 'Крайний срок',
        completedAt: 'Завершено',
        number: 'Номер',
        boardId: 'Статус',
        stageId: 'Этап',
        projectId: 'Проект',
        assignedId: 'Назначена исполнителю',
        calendarSrc: 'Ссылка на календарь'
      },
      projectEditing: false,
      newProject: {},
      newProjectUsers: [],
      customerEditing: false,
      newCustomer: {},
      unwatchers: []
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  mounted() {
    this.fetchData()
  },
  watch: {
    // при изменениях маршрута запрашиваем данные снова
    $route: 'fetchData'
    // task: {
    //   handler: function () {
    //     this.debouncedTaskModified()
    //   },
    //   deep: true
    // },
    // taskUsers: 'debouncedTaskModified'
  },
  computed: {
    ...mapGetters([
      'isAdmin',
      'isAuthenticated',
      'currentUser',
      'selAllCustomers',
      'selAllUsers'
    ]),
    pageName: function () {
      return 'Задача'
    },
    startedFromNow() {
      if (!this.task.startDate) {
        return ''
      }
      return moment(this.task.startDate).startOf('day').fromNow()
    },
    endedFromNow() {
      if (!this.task.endDate) {
        return ''
      }
      return moment(this.task.endDate).endOf('day').fromNow()
    },
    startedTask() {
      if (!this.task.startDate) {
        return ''
      }
      return moment(this.task.startDate).format('dd L')
    },
    endedTask() {
      if (!this.task.endDate) {
        return ''
      }
      return moment(this.task.endDate).format('dd L')
    },
    selAllProjects() {
      let result = {}
      if (this.taskProjects) {
        this.taskProjects.forEach((p) => {
          result[p.id] = p.name
        })
      }
      return result
    },
    projectsCustomers() {
      let plist = []
      if (this.taskProjects) {
        let unidata = this.taskProjects.filter(
          (thing, index, self) =>
            index === self.findIndex((t) => t.customerId === thing.customerId)
        )

        let unicusts = [
          ...new Set(
            unidata.map((item) => ({
              id: item.customerId,
              name: item.customer.name || '(без заказчика)',
              disabled: true
            }))
          )
        ]

        for (let uc of unicusts) {
          plist.push(uc)
          let pd = this.taskProjects.filter((t) => {
            return t.customerId === uc.id
          })
          for (let p of pd) {
            plist.push(p)
          }
        }
      }

      return plist
    }
  },
  methods: {
    ...mapActions(['whoami', 'fetchAllCustomers', 'fetchAllUsers']),
    scrollMeTo(refName) {
      let element = this.$refs[refName]
      let top = element.offsetTop
      window.scrollTo(0, top)
    },

    startWatchers() {
      this.$nextTick(this.startWatchersNext)
    },

    startWatchersNext() {
      this.unwatchers = [
        this.$watch('task', this.debouncedTaskModified, {
          deep: true
        }),
        this.$watch('taskUsers', this.debouncedTaskModified)
      ]
      // console.log('start watchers')
      // console.log(this)
    },

    stopWatchers() {
      if (this.unwatchers) {
        this.unwatchers.forEach((un) => {
          // console.log('unwatch')
          // console.log(un)
          un()
        })
        this.unwatchers = []
      }
      // console.log('stop watchers')
      // console.log(this)
    },

    valForKey(key, tch) {
      switch (key) {
        case 'assignedId':
          return this.taskAllSpaceUsers[tch]
        case 'projectId': {
          let pd = this.taskProjects.filter((t) => {
            return t.id === tch
          })
          return pd.name
        }
        case 'date':
        case 'startDate':
        case 'endDate':
        case 'completedAt':
          return this.formatDateDisplay(tch)
        default:
          return tch
      }
    },

    fillTaskData(respData) {
      this.stopWatchers()
      // this.fetching = true
      // this.$nextTick(() => {
      //   this.fetching = false
      // })

      if (respData.changes) {
        for (let k in respData.changes) {
          if (k == 'name' || k == 'description') {
            continue
          }
          this.task[k] = respData[k]
          if (k == 'number') {
            this.taskNumber = respData.number
          }
          // console.log(k)
        }
        this.task.id = respData.id
        this.task.createdAt = respData.createdAt
        this.task.updatedAt = respData.updatedAt
        this.task.userFlags = respData.userFlags
      } else {
        this.task = respData
        this.taskNumber = this.task.number
      }

      this.taskBoards = respData.boards
      this.taskStages = respData.stages
      this.taskProjects = respData.projects

      // taskUsers
      this.taskUsers = respData.taskUsers

      // taskAllUsers
      let result = {}
      if (respData.taskAllUsers) {
        respData.taskAllUsers.forEach((u) => {
          result[u.id] =
            u.fullname + ' (' + (u.username ? u.username : u.tgId) + ')'
        })
      }
      this.taskAllUsers = result

      // spaceAllUsers
      let result2 = {}
      if (respData.spaceAllUsers) {
        respData.spaceAllUsers.forEach((u) => {
          result2[u.id] =
            u.fullname + ' (' + (u.username ? u.username : u.tgId) + ')'
        })
      }
      this.taskAllSpaceUsers = result2

      this.startWatchers()
    },
    fetchData() {
      this.whoami()
      this.fetchAllCustomers()
      this.fetchAllUsers()

      if (this.$route.params) {
        this.loading = true
        // подгружаем данные задачи
        tasks
          .getById(this.$route.params.id)
          .then((response) => {
            this.fillTaskData(response.data)
            this.exists = true
            // this.fetchUsers()
            this.fetchMemos()
            this.fetchReminders()
            this.loading = false
          })
          .catch((error) => {
            // this.fetching = true
            // this.$nextTick(() => {
            //   this.fetching = false
            // })
            this.stopWatchers()
            if (error.response.status == 404) {
              this.task = emptyTask(
                this.$route.params.id,
                this.$route.query.boardId,
                this.$route.query.stageId
              )
              this.taskNumber = null
              // console.log(this.$refs.info)
              this.exists = false
              // FIXME: передавать признак новой задачи, чтобы не искать заново задачу по ID
              this.fetchTaskClassifiers()
              this.fetchAllProjects()

              this.fetchSpaceUsers()
            } else {
              // this.task = emptyTask('', '', '')
              // this.taskNumber = null
              catchErr(error)
            }
            this.startWatchers()
            this.loading = false
          })
      }
    },
    fetchTaskClassifiers() {
      tasks
        .getBoards(this.$route.params.id)
        .then((resp) => {
          this.taskBoards = resp.data
        })
        .catch((error) => {
          this.taskBoards = []
          catchErr(error)
        })
      tasks
        .getStages(this.$route.params.id)
        .then((resp) => {
          this.taskStages = resp.data
        })
        .catch((error) => {
          this.taskStages = []
          catchErr(error)
        })
    },
    fetchAllProjects() {
      tasks
        .getProjects(this.$route.params.id)
        .then((resp) => {
          this.taskProjects = resp.data
        })
        .catch((error) => {
          this.taskProjects = []
          catchErr(error)
        })
    },
    fetchMemos() {
      tasks
        .getMemos(this.task.id)
        .then((response) => {
          this.taskMemos = response.data
        })
        .catch((error) => {
          if (error.response.status != 404) {
            this.taskMemos = []
            catchErr(error)
          }
        })
    },
    fetchReminders() {
      tasks
        .getReminders(this.task.id)
        .then((response) => {
          this.taskReminders = response.data
        })
        .catch((error) => {
          if (error.response.status != 404) {
            this.taskReminders = []
            catchErr(error)
          }
        })
    },

    fetchSpaceUsers() {
      tasks
        .getAllSpaceUsers(this.task.id)
        .then((response) => {
          let result = {}
          response.data.forEach((u) => {
            result[u.id] =
              u.fullname + ' (' + (u.username ? u.username : u.tgId) + ')'
          })
          this.taskAllSpaceUsers = result
        })
        .catch((error) => {
          if (error.response.status != 404) {
            this.taskAllSpaceUsers = []
            catchErr(error)
          }
        })
    },

    fetchHistory() {
      tasks
        .getHistory(this.task.id)
        .then((response) => {
          let rdata = response.data
          rdata.createdAt = new Date(rdata.createdAt)
          this.taskHistory = rdata
        })
        .catch((error) => {
          if (error.response.status != 404) {
            this.taskHistory = []
            catchErr(error)
          }
        })
    },

    loadCurrentReminder(id) {
      this.selectedReminderId = id
      this.currentReminder = {
        id: this.selectedReminderId,
        taskId: this.task.id,
        text: '',
        receiver: 0,
        receiverName: '',
        raiseAt: new Date(),
        raised: false,
        userId: this.currentUser.id,
        user: this.currentUser
      }
      if (this.selectedReminderId) {
        tasks
          .getReminderById(this.task.id, this.selectedReminderId)
          .then((response) => {
            let remData = response.data
            remData.raiseAt = new Date(remData.raiseAt)
            remData.raised = false
            this.currentReminder = remData
          })
          .catch((error) => {
            if (error.response.status == 404) {
              // console.log("404 - new");
            } else {
              catchErr(error)
            }
          })
      }
    },
    addReminder() {
      this.loadCurrentReminder(generateUUID())
    },
    saveCurrentReminder() {
      let remData = {}
      for (let k in this.currentReminder) remData[k] = this.currentReminder[k]
      remData.raiseAt = new Date(remData.raiseAt)

      // console.log(remData)

      // Save to storage
      tasks
        .saveReminder(this.task.id, remData.id, remData)
        .then(() => {
          this.$busEmit('flash', 'Напоминание сохранено')
          this.fetchReminders()
          this.loadCurrentReminder(null)
        })
        .catch((error) => {
          catchErr(error)
        })

      // console.log(this.currentReminder)
    },
    sendComment() {
      if (this.taskComment) {
        tasks
          .saveMemo(this.task.id, {
            comment: this.taskComment
          })
          .then(() => {
            this.taskComment = ''
            this.fetchMemos()
          })
      }
    },
    focus: function () {
      this.$refs.taskName.focus()
    },
    formatDateDisplay(date) {
      let options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }
      let d = new Date(date)
      return d.toLocaleDateString('ru-RU', options)
    },
    formatDateTimeDisplay(date) {
      let options = {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }
      let d = new Date(date)
      return d.toLocaleDateString('ru-RU', options)
    },
    debouncedTaskModified() {
      // if (this.fetching) {
      //   return
      // }

      if (!this.debfunc) {
        this.debfunc = debounce(this.taskModified, 500)
      }
      this.debfunc()
    },
    momentFromNow(t) {
      return moment(t).fromNow()
    },
    taskModified() {
      this.saveTask()
    },
    pasteImg(e) {
      const dT = e.clipboardData // || window.clipboardData;
      for (var i = 0; i < dT.items.length; i++) {
        if (dT.items[i].kind == 'file' && dT.items[i].type == 'image/png') {
          // get the blob
          var imageFile = dT.items[i].getAsFile()

          // read the blob as a data URL
          var fileReader = new FileReader()
          fileReader.onload = (le) => {
            // create an image
            this.task.screenshot = le.target.result
          }

          // TODO: Error Handling!
          // fileReader.onerror = ...

          fileReader.readAsDataURL(imageFile)

          e.preventDefault()

          // only paste 1 image at a time
          break
        }
      }
    },
    openImg() {
      window.open(this.task.screenshot, '_blank').focus()
    },
    createProjectOption() {
      this.newProject = {
        id: generateUUID(),
        name: '',
        description: '',
        customerId: ''
      }
      // this.selected = this.current.id
      this.newProjectUsers = []
      this.projectEditing = true
      this.$refs.projectSelect.hideOptions()
    },
    cancelProject() {
      this.newProject = {
        id: '',
        name: '',
        description: '',
        customerId: ''
      }
      this.newProjectUsers = []
      this.projectEditing = false
    },
    saveProject() {
      if (this.newProject.name == '') {
        return
      }
      let saveData = {}
      for (let k in this.newProject) saveData[k] = this.newProject[k]

      projects
        .save(saveData)
        .then((response) => {
          this.newProject = response.data
          this.projectEditing = false

          tasks
            .getProjects(this.$route.params.id)
            .then((resp) => {
              this.taskProjects = resp.data
              this.task.projectId = this.newProject.id
            })
            .catch((error) => {
              this.taskProjects = []
              catchErr(error)
            })

          this.$busEmit('flash', 'Проект сохранен')
        })
        .catch((error) => {
          catchErr(error)
        })

      let saveUsersData = []
      for (let k of this.newProjectUsers) saveUsersData.push(k)
      // console.log(saveUsersData)

      projects
        .saveUsers(this.newProject.id, saveUsersData)
        // .then(() => {
        //   this.fetchAllProjects()
        // })
        .catch((error) => {
          catchErr(error)
        })
    },

    createCustomerOption() {
      this.newCustomer = {
        id: generateUUID(),
        name: '',
        description: ''
      }
      this.customerEditing = true
      this.$refs.customerSelect.hideOptions()
    },
    cancelCustomer() {
      this.newCustomer = {
        id: '',
        name: '',
        description: ''
      }
      this.customerEditing = false
    },
    saveCustomer() {
      if (this.newCustomer.name == '') {
        return
      }
      let saveData = {}
      for (let k in this.newCustomer) saveData[k] = this.newCustomer[k]

      customers
        .save(saveData)
        .then((response) => {
          this.newCustomer = response.data
          this.customerEditing = false
          this.fetchAllCustomers()
          this.newProject.customerId = this.newCustomer.id
          this.$busEmit('flash', 'Заказчик сохранен')
        })
        .catch((error) => {
          catchErr(error)
        })
    },
    saveTask() {
      if (this.task.name == '') {
        return
      }

      // data to save
      let taskData = {}
      for (let k in this.task) taskData[k] = this.task[k]

      if (taskData.startDate) {
        taskData.startDate = new Date(taskData.startDate)
      }
      if (taskData.endDate) {
        taskData.endDate = new Date(taskData.endDate)
      }
      if (taskData.completedAt) {
        taskData.completedAt = new Date(taskData.completedAt)
      }
      if (this.isAdmin) {
        let saveUsersData = []
        if (this.taskUsers) {
          for (let k of this.taskUsers) saveUsersData.push(k)
        }
        taskData.taskUsers = saveUsersData
      }
      // console.log(taskData)

      // Save to storage
      tasks
        .save(taskData)
        .then((response) => {
          this.fillTaskData(response.data)
          // this.task = response.data
          // this.task.number = response.data.number
          // this.taskNumber = this.task.number
          // this.task.space = response.data.space
          // this.task.completedAt = response.data.completedAt

          // this.$busEmit('flash', 'Задача сохранена')
          // this.fetchAllTasks()
          // this.task.name = ''
          // this.task.boardName = ''
          // this.$router.push(this.prevRoute ? this.prevRoute.path : '/')
        })
        .catch((error) => {
          catchErr(error)
        })
    }
  }
}
</script>
