import http from './http'
const boards = {
  getAll: () => http.get('/allboards'),
  getById: (id) => http.get(`/allboards/${id}`),
  save: (lastSelectedId, data) =>
    http.post(
      lastSelectedId ? `/allboards?fromid=${lastSelectedId}` : '/allboards',
      data
    )
}
export default boards
