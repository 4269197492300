import http from '@/api/http'
import tasks from '@/api/tasks'
import { catchErr } from '@/error'

export default {
  state: {
    boards: [],
    stages: [],
    tasks: []
  },
  mutations: {
    setBoards(state, boards) {
      state.boards = boards
    },
    setStages(state, stages) {
      state.stages = stages
    },
    setTasks(state, tasks) {
      state.tasks = tasks
    }
  },
  actions: {
    fetchTaskClassifiers({ commit }) {
      http.get('/boards').then((resp) => {
        commit('setBoards', resp.data)
      })
      http.get('/stages').then((resp) => {
        commit('setStages', resp.data)
      })
    },
    fetchAllTasks({ commit, rootState }) {
      tasks
        .getAll(rootState.setts.showCompletedTasks)
        .then((response) => {
          commit('setTasks', response.data)
        })
        .catch((error) => {
          commit('setTasks', [])
          catchErr(error)
        })
    },
    moveTaskStageBoard({ dispatch }, p) {
      tasks
        .move(p.id, {
          boardId: p.boardId,
          stageId: p.stageId
        })
        .then(() => {
          dispatch('fetchAllTasks')
        })
        .catch((error) => {
          catchErr(error)
        })
    }
  },
  getters: {
    allBoards(state) {
      return state.boards
    },
    allStages(state) {
      return state.stages
    },
    allTasks(state) {
      return state.tasks
    },
    selStages(state) {
      return state.stages
    }
  }
}
