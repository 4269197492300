<template>
  <div
    class="
      flex
      h-full
      w-full
      items-end
      md:items-center
      justify-center
      z-10
      md:max-w-4xl
      ml-auto
      mr-auto
    "
    style="min-height: 150px"
    @keydown.esc="$router.push(prevRoute ? prevRoute.path : '/')"
  >
    <div class="md:max-w-5xl mx-auto w-full h-full flex-1 relative">
      <t-form
        title="Настройки"
        @closeForm="$router.push(prevRoute ? prevRoute.path : '/')"
      >
        <div class="md:p-6 p-2">
          <div class="flex justify-start items-center mb-5">
            <img
              v-if="currentUser.photo"
              class="h-16 w-16 rounded-full ring-2 ring-white hover:shadow-lg"
              v-bind:src="currentUser.photo"
              alt=""
            />
            <div
              class="
                appearance-none
                text-xl
                w-full
                md:w-64
                py-2
                px-4
                text-gray-800
                leading-tight
              "
              v-text="currentUser.fullname"
            ></div>
            <a
              @click.prevent="userexit()"
              href="#"
              class="
                rounded-lg
                px-3
                py-2
                font-medium
                inline-flex
                items-center
                text-blue-500
                hover:bg-gray-100
              "
            >
              <svg
                class="fill-current inline-block w-5 h-5 mr-1"
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="sign-out-alt"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M272 112v51.6h-96c-26.5 0-48 21.5-48 48v88.6c0 26.5 21.5 48 48 48h96v51.6c0 42.6 51.7 64.2 81.9 33.9l144-143.9c18.7-18.7 18.7-49.1 0-67.9l-144-144C323.8 48 272 69.3 272 112zm192 144L320 400v-99.7H176v-88.6h144V112l144 144zM96 64h84c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12H96c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h84c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12H96c-53 0-96-43-96-96V160c0-53 43-96 96-96z"
                ></path>
              </svg>
            </a>
          </div>
          <t-line header="Войти от имени" v-if="isRoot">
            <t-rich-select
              v-model="debugUserId"
              :options="currentUser.debugUsers"
              value-attribute="id"
              text-attribute="name"
              placeholder="Войти от имени пользователя..."
              noResultsText="Не найдено..."
              searchBoxPlaceholder="Найти..."
              title="Войти от имени пользователя"
            ></t-rich-select>
          </t-line>
          <t-line header="Права доступа">
            <div
              class="
                ml-4
                text-xs
                inline-flex
                items-center
                leading-sm
                uppercase
                px-3
                py-1
                text-gray-700
              "
              v-if="isRoot"
            >
              Владелец системы
            </div>
            <div
              class="
                ml-4
                text-xs
                inline-flex
                items-center
                leading-sm
                uppercase
                px-3
                py-1
                text-gray-700
              "
              v-for="(rname, idx) in currentUser.currentRoleNames"
              v-bind:key="idx"
            >
              {{ rname }}
            </div>
          </t-line>
          <t-line header="Окружение">
            <div
              class="
                ml-4
                mb-2
                text-xs
                inline-flex
                items-center
                leading-sm
                uppercase
                px-3
                py-1
                rounded-full
                bg-white
                text-gray-700
                border
              "
              v-for="sp in currentUser.spaces"
              v-bind:key="sp.id"
              :class="
                sp.name == currentUser.currentSpaceState.spaceName
                  ? 'font-bold'
                  : ''
              "
            >
              <span v-if="sp.name == currentUser.currentSpaceState.spaceName">{{
                sp.name
              }}</span>
              <a v-else href="#" @click.prevent="currentSpaceId = sp.id">{{
                sp.name
              }}</a>
            </div>
          </t-line>

          <t-line header="Показывать завершенные задачи">
            <label class="flex items-center">
              <t-checkbox v-model="showCompleted" />
              <span class="ml-2 text-sm text-gray-700"
                >Задачи старше 14 дней</span
              >
            </label>
          </t-line>

          <t-accordion-item
            class="border mb-2 mx-1"
            v-if="isAdmin"
            title="Тариф и оплата"
          >
            <SpaceState
              hideTitle="true"
              class="
                p-3
                border
                rounded
                shadow-sm
                text-sm
                bg-white
                border-gray-100
              "
            ></SpaceState>
          </t-accordion-item>

          <t-accordion-item
            class="border mb-2 mx-1"
            v-if="isAdmin"
            title="Этапы задач окружения"
          >
            <Stage></Stage>
          </t-accordion-item>

          <t-accordion-item
            class="border mb-2 mx-1"
            v-if="isAdmin"
            title="Статусы задач окружения"
          >
            <Board></Board>
          </t-accordion-item>

          <t-accordion-item
            class="border mb-2 mx-1"
            v-if="isAdmin"
            title="Заказчики проектов окружения"
          >
            <Customer></Customer>
          </t-accordion-item>

          <t-accordion-item
            class="border mb-2 mx-1"
            v-if="isAdmin"
            title="Проекты окружения"
          >
            <Project></Project>
          </t-accordion-item>

          <t-accordion-item
            title="Резервное копирование и восстановление"
            v-if="isRoot"
            class="border mb-2 mx-1"
          >
            <div class="p-3">
              <div
                class="flex justify-start items-center mb-5 space-x-4"
                v-if="isRoot"
              >
                <label class="text-sm">Сохранить бэкап</label>
                <t-button
                  href="/api/v1/backup_all_spaces"
                  variant="secondary"
                  tagName="a"
                  >Бэкап</t-button
                >
              </div>
              <div
                class="flex justify-start items-center mb-5 space-x-4"
                v-if="isRoot"
              >
                <label class="text-sm">Восстановить из бэкапа</label>
                <form
                  action="/api/v1/restore_all_spaces?trunc=true"
                  enctype="multipart/form-data"
                  method="post"
                  class="md:flex justify-start space-x-2"
                >
                  <div class="relative">
                    <input
                      type="file"
                      name="backup"
                      id="backup"
                      class="text-sm"
                    />
                  </div>
                  <t-button variant="secondary" type="submit"
                    >Восстановить</t-button
                  >
                </form>
              </div>
            </div>
          </t-accordion-item>
        </div>
      </t-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Board from './Board'
import Stage from './Stage'
import SpaceState from './SpaceState'
import Customer from './Customer'
import Project from './Project'
import users from '@/api/users'
import { catchErr } from '@/error'

export default {
  components: {
    Board,
    Stage,
    Customer,
    Project,
    SpaceState
  },
  data() {
    return {
      prevRoute: null
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['currentUser', 'showCompletedTasks', 'isAdmin', 'isRoot']),
    showCompleted: {
      get() {
        return this.showCompletedTasks
      },
      set(value) {
        this.setShowCompletedTasks(value)
      }
    },
    currentSpaceId: {
      get() {
        return this.currentUser.currentSpaceId
      },
      set(value) {
        this.saveCurrentSpaceId(value)
      }
    },
    debugUserId: {
      get() {
        return this.currentUser.id
      },
      set(id) {
        this.debugAs(id)
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  watch: {
    $route: 'fetchData'
  },
  methods: {
    ...mapActions([
      'whoami',
      'logout',
      'setShowCompletedTasks',
      'debugAs',
      'fetchAllCustomers',
      'fetchAllProjects',
      'fetchAllUsers'
    ]),
    fetchData() {
      this.whoami().then(() => {
        this.fetchAllProjects()
        this.fetchAllCustomers()
        this.fetchAllUsers()
      })
    },
    userexit() {
      this.logout()
      // Back to Main Page
      this.$router.push('/')
    },
    saveCurrentSpaceId(spid) {
      users
        .saveCurrentSpaceId(this.currentUser.id, spid)
        .then(() => {
          this.fetchData()
          this.$busEmit('changeSpace')
          this.$busEmit('flash', 'Выбрано новое окружение')
        })
        .catch((error) => {
          catchErr(error)
        })
    }
    // saveSettings() {
    //   // Save to localStorage
    //   localStorage.setItem("TG-dateDisplay", this.newDateDisplay);

    //   this.$busEmit("saveSettings", {
    //     // username: this.userName,
    //     dateDisplay: this.newDateDisplay,
    //   });

    //   // Show Flash message
    //   this.$busEmit("flash", "Настройки обновлены");

    //   // Back to Main Page
    //   this.$router.push("/");
    // },
  }
}
</script>
