export default {
  state: {
    showCompletedTasks: false
  },
  mutations: {
    setShowCompletedTasks(state, f) {
      state.showCompletedTasks = f
    }
  },
  actions: {
    setShowCompletedTasks({ dispatch, commit }, f) {
      commit('setShowCompletedTasks', f)
      dispatch('fetchAllTasks')
    }
  },
  getters: {
    showCompletedTasks(state) {
      return state.showCompletedTasks
    }
  }
}
