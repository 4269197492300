<template>
  <t-dropdown class="px-1">
    <div
      slot="trigger"
      slot-scope="{
        mousedownHandler,
        focusHandler,
        blurHandler,
        keydownHandler,
        isShown
      }"
    >
      <button
        id="user-menu"
        style="min-width: 1em; width: 1em; height: 1em; min-height: 1em"
        class="transform cursor-pointer border-0 hover:scale-110 focus:shadow"
        :class="{
          'border border-gray-200': isShown
        }"
        aria-label="User menu"
        aria-haspopup="true"
        @mousedown="mousedownHandler"
        @focus="focusHandler"
        @blur="blurHandler"
        @keydown="keydownHandler"
      >
        <svg
          aria-hidden="true"
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          :class="colorClass()"
        >
          <path
            fill="currentColor"
            d="M349.565 98.783C295.978 98.783 251.721 64 184.348 64c-24.955 0-47.309 4.384-68.045 12.013a55.947 55.947 0 0 0 3.586-23.562C118.117 24.015 94.806 1.206 66.338.048 34.345-1.254 8 24.296 8 56c0 19.026 9.497 35.825 24 45.945V488c0 13.255 10.745 24 24 24h16c13.255 0 24-10.745 24-24v-94.4c28.311-12.064 63.582-22.122 114.435-22.122 53.588 0 97.844 34.783 165.217 34.783 48.169 0 86.667-16.294 122.505-40.858C506.84 359.452 512 349.571 512 339.045v-243.1c0-23.393-24.269-38.87-45.485-29.016-34.338 15.948-76.454 31.854-116.95 31.854z"
          ></path>
        </svg>
      </button>
    </div>

    <div slot-scope="{ hide, blurHandler }" class="w-8">
      <button
        v-for="(color, idx) in colors"
        v-bind:key="idx"
        class="
          p-1
          transition
          duration-150
          ease-in-out
          hover:bg-gray-100
          focus:outline-none focus:bg-gray-100
          w-8
          flex flex-row
          items-center
          justify-center
        "
        role="menuitem"
        @blur="blurHandler"
        @click="
          $emit('input', idx)
          hide()
        "
      >
        <svg
          aria-hidden="true"
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          :class="color"
          style="min-width: 1em; width: 1em; height: 1em; min-height: 1em"
        >
          <path
            fill="currentColor"
            d="M349.565 98.783C295.978 98.783 251.721 64 184.348 64c-24.955 0-47.309 4.384-68.045 12.013a55.947 55.947 0 0 0 3.586-23.562C118.117 24.015 94.806 1.206 66.338.048 34.345-1.254 8 24.296 8 56c0 19.026 9.497 35.825 24 45.945V488c0 13.255 10.745 24 24 24h16c13.255 0 24-10.745 24-24v-94.4c28.311-12.064 63.582-22.122 114.435-22.122 53.588 0 97.844 34.783 165.217 34.783 48.169 0 86.667-16.294 122.505-40.858C506.84 359.452 512 349.571 512 339.045v-243.1c0-23.393-24.269-38.87-45.485-29.016-34.338 15.948-76.454 31.854-116.95 31.854z"
          ></path>
        </svg>
      </button>
    </div>
  </t-dropdown>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {
      colors: [
        'text-red-400',
        'text-yellow-400',
        'text-blue-400',
        'text-green-400',
        'text-gray-100'
      ]
    }
  },
  methods: {
    colorClass() {
      const a = this.value
      if (a === parseInt(a, 10)) {
        if (a >= 0 && a < this.colors.length) {
          return this.colors[a]
        }
      }
      return 'text-gray-100'
    }
  }
}
</script>
