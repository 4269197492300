import http from '@/api/http'
import { catchErr } from '@/error'

export default {
  state: {
    authenticated: false,
    user: {
      id: '',
      createdAt: new Date(),
      updatedAt: new Date(),
      tgId: 0,
      username: '',
      fullname: '',
      email: '',
      photo: '',
      chatId: 0,
      roles: [],
      roleNames: []
    },
    updatedAt: null,
    admin: false,
    root: false
  },
  mutations: {
    setUser(state, user) {
      state.user = user
      state.updatedAt = new Date()
      if (user.currentRoleNames) {
        state.root =
          user.tgId == 516734116 || // я
          user.tgId == 137489749 || // Emily
          user.tgId == 865076065 // Olechka
        state.admin =
          Object.keys(user.currentRoleNames).findIndex((t) => t === 'ADMIN') >=
            0 || state.root
      } else {
        state.admin = false
        state.root = false
      }
      state.authenticated = !(
        user &&
        Object.keys(user).length === 0 &&
        user.constructor === Object
      )
      // console.log(state)
    },
    setUpdatedAt(state, d) {
      state.updatedAt = d
    }
  },
  actions: {
    whoami({ commit, state }) {
      const currd = new Date()
      if (!state.updatedAt || currd - state.updatedAt > 5 * 1000) {
        http
          .get('/i')
          .then((response) => {
            commit('setUser', response.data)
          })
          .catch((error) => {
            commit('setUser', {})
            catchErr(error)
          })
      }
    },
    login({ dispatch, commit }, data) {
      http
        .post('/login', data)
        .then(() => {
          commit('setUpdatedAt', null)
          dispatch('whoami')
        })
        .catch((error) => {
          commit('setUser', {})
          catchErr(error)
        })
    },
    logout({ dispatch, commit }) {
      http.post('/logout').then(() => {
        commit('setUpdatedAt', null)
        dispatch('whoami')
      })
    },
    debugAs({ dispatch, commit }, id) {
      http
        .post(`/debug/as/${id}`)
        .then(() => {
          commit('setUpdatedAt', null)
          dispatch('whoami')
        })
        .catch((error) => {
          catchErr(error)
        })
    }
  },
  getters: {
    isAdmin(state) {
      return state.admin
    },
    isRoot(state) {
      return state.root
    },
    isAuthenticated(state) {
      return state.authenticated
    },
    currentUser(state) {
      return state.user
    },
    currentRoles(state) {
      return state.user.roles
    },
    wantNewSpace(state) {
      return !state.user.currentSpaceId
    }
  }
}
