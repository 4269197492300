<template>
  <div
    class="
      fixed
      w-full
      z-50
      flex
      inset-0
      items-start
      justify-center
      pointer-events-none
      md:mt-5
    "
  >
    <transition
      name="flash-message-transition"
      enter-active-class="transition ease-out duration-300 transform"
      enter-class="-translate-y-5 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition ease-in duration-100 transform"
      leave-class="opacity-100"
      leave-to-class="opacity-0 -translate-y-5"
    >
      <template v-if="message">
        <div role="alert" class="w-full px-4 py-4 w-full md:max-w-sm">
          <t-alert :variant="variant" show>
            {{ message }}
          </t-alert>
          <!-- <div class="flex items-center">
            <div class="flex-shrink-0 mr-3">
              <svg
                class="h-6 w-6 text-gray-800"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="text-gray-800 text-base" v-text="message"></div>
          </div> -->
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
import { eventBus } from '@/eventBus'
export default {
  data: function () {
    return {
      message: '',
      variant: ''
    }
  },
  created() {
    eventBus.$on('flash', this.showFlashMessage)
  },
  beforeDestroy() {
    eventBus.$off('flash')
  },
  methods: {
    showFlashMessage(event) {
      this.message = event.detail.message
      this.variant = event.detail.data
      setTimeout(() => {
        this.message = ''
        this.variant = ''
      }, 1000)
    }
  }
}
</script>
