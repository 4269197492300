<template>
  <div class="h-full">
    <Alert></Alert>
    <div class="flex flex-col h-full border-blue-700">
      <div class="flex-1 h-full">
        <div class="w-11/12 mx-auto md:px-4 py-4 h-full">
          <div v-if="isAuthenticated" class="h-full">
            <div v-if="wantNewSpace" class="h-full">
              <NewSpace></NewSpace>
            </div>
            <div v-else class="h-full">
              <KanbanHead />
              <transition name="component-fade" mode="out-in">
                <div v-if="$route.path == '/'" class="h-full">
                  <Kanban />
                </div>
                <div v-else class="h-full"><router-view></router-view></div>
              </transition>
            </div>
          </div>
          <Welcome v-else></Welcome>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Alert from '@/components/Alert.vue'
import Kanban from '@/components/Kanban.vue'
import KanbanHead from '@/components/KanbanHead.vue'
import Welcome from '@/components/Welcome.vue'
import NewSpace from '@/components/NewSpace.vue'

export default {
  name: 'Home',
  components: {
    Alert,
    Kanban,
    KanbanHead,
    Welcome,
    NewSpace
  },
  computed: mapGetters(['isAuthenticated', 'wantNewSpace']),
  mounted() {
    this.whoami()
  },
  watch: {
    $route: 'whoami'
  },
  methods: {
    ...mapActions(['whoami'])
  }
}
</script>

<style></style>
