var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-dropdown',{staticClass:"px-1",scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
    var mousedownHandler = ref.mousedownHandler;
    var focusHandler = ref.focusHandler;
    var blurHandler = ref.blurHandler;
    var keydownHandler = ref.keydownHandler;
    var isShown = ref.isShown;
return _c('div',{},[_c('button',{staticClass:"transform cursor-pointer border-0 hover:scale-110 focus:shadow",class:{
        'border border-gray-200': isShown
      },staticStyle:{"min-width":"1em","width":"1em","height":"1em","min-height":"1em"},attrs:{"id":"user-menu","aria-label":"User menu","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('svg',{class:_vm.colorClass(),attrs:{"aria-hidden":"true","focusable":"false","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512"}},[_c('path',{attrs:{"fill":"currentColor","d":"M349.565 98.783C295.978 98.783 251.721 64 184.348 64c-24.955 0-47.309 4.384-68.045 12.013a55.947 55.947 0 0 0 3.586-23.562C118.117 24.015 94.806 1.206 66.338.048 34.345-1.254 8 24.296 8 56c0 19.026 9.497 35.825 24 45.945V488c0 13.255 10.745 24 24 24h16c13.255 0 24-10.745 24-24v-94.4c28.311-12.064 63.582-22.122 114.435-22.122 53.588 0 97.844 34.783 165.217 34.783 48.169 0 86.667-16.294 122.505-40.858C506.84 359.452 512 349.571 512 339.045v-243.1c0-23.393-24.269-38.87-45.485-29.016-34.338 15.948-76.454 31.854-116.95 31.854z"}})])])])}},{key:"default",fn:function(ref){
      var hide = ref.hide;
      var blurHandler = ref.blurHandler;
return _c('div',{staticClass:"w-8"},_vm._l((_vm.colors),function(color,idx){return _c('button',{key:idx,staticClass:"\n        p-1\n        transition\n        duration-150\n        ease-in-out\n        hover:bg-gray-100\n        focus:outline-none focus:bg-gray-100\n        w-8\n        flex flex-row\n        items-center\n        justify-center\n      ",attrs:{"role":"menuitem"},on:{"blur":blurHandler,"click":function($event){_vm.$emit('input', idx)
        hide()}}},[_c('svg',{class:color,staticStyle:{"min-width":"1em","width":"1em","height":"1em","min-height":"1em"},attrs:{"aria-hidden":"true","focusable":"false","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512"}},[_c('path',{attrs:{"fill":"currentColor","d":"M349.565 98.783C295.978 98.783 251.721 64 184.348 64c-24.955 0-47.309 4.384-68.045 12.013a55.947 55.947 0 0 0 3.586-23.562C118.117 24.015 94.806 1.206 66.338.048 34.345-1.254 8 24.296 8 56c0 19.026 9.497 35.825 24 45.945V488c0 13.255 10.745 24 24 24h16c13.255 0 24-10.745 24-24v-94.4c28.311-12.064 63.582-22.122 114.435-22.122 53.588 0 97.844 34.783 165.217 34.783 48.169 0 86.667-16.294 122.505-40.858C506.84 359.452 512 349.571 512 339.045v-243.1c0-23.393-24.269-38.87-45.485-29.016-34.338 15.948-76.454 31.854-116.95 31.854z"}})])])}),0)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }