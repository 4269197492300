<template>
  <div
    class="bg-white px-4 py-5 sm:px-6"
    :class="
      (gray ? 'bg-gray-50' : 'bg-white') +
      (noHeader ? '' : ' sm:grid sm:grid-cols-3 sm:gap-4')
    "
  >
    <div
      class="text-sm font-medium text-gray-500 flex items-center"
      v-text="header"
      v-if="!noHeader"
    ></div>
    <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    gray: {
      type: Boolean,
      default: false
    },
    noHeader: {
      type: Boolean,
      default: false
    },
    header: {
      type: String,
      default: ''
    }
  }
}
</script>
