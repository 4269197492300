<template>
  <div
    class="
      flex
      items-center
      justify-center
      font-bold font-sans
      rounded-full
      ring-2 ring-white
      text-white
    "
    :class="bgcolor"
  >
    {{ chars }}
  </div>
</template>

<script>
export default {
  props: ['username'],
  computed: {
    chars() {
      const n = this.username.split(' ')
      let firstword = n[0]
      let first = ' '
      if (firstword.length > 0) {
        first = firstword[0].toUpperCase()
      }
      let lastword = n[n.length - 1]
      let last = ' '
      if (lastword.length > 0) {
        last = lastword[0].toUpperCase()
      }
      if (n.length == 1) {
        if (first.length > 1) {
          last = first[1].toUpperCase()
        } else {
          last = ' '
        }
      }
      return first + last
    },
    numberFromChars() {
      // numberFromText("AA");
      const charCodes = this.chars
        .split('') // => ["A", "A"]
        .map((char) => char.charCodeAt(0)) // => [65, 65]
        .join('') // => "6565"
      return parseInt(charCodes, 10)
    },
    bgcolor() {
      return this.colors[this.numberFromChars % this.colors.length]
    }
  },
  data() {
    return {
      colors: [
        'bg-red-400',
        'bg-yellow-400',
        'bg-blue-400',
        'bg-green-400',
        'bg-gray-400',
        'bg-purple-400',
        'bg-indigo-400',
        'bg-pink-400',
        'bg-sky-400'
      ]
    }
  }
}
</script>
