<template>
  <div>
    <div
      class="
        flex
        md:flex-row
        flex-col
        justify-between
        items-center
        mb-2
        md:pb-4 md:space-y-0
        space-y-2
      "
    >
      <div
        class="
          flex
          md:justify-start md:flex-row
          flex-col
          justify-center
          items-center
          space-x-4
        "
      >
        <Logo></Logo>

        <div class="flex md:items-start items-center flex-col space-y-2">
          <h1
            class="
              text-xl
              md:text-2xl
              text-gray-800
              font-semibold
              md:text-left
              text-center
            "
            v-text="greetText()"
          ></h1>
          <div
            v-text="formatDateDisplay(new Date())"
            class="text-sm text-gray-600"
          ></div>
        </div>
      </div>

      <div
        class="
          flex
          justify-end
          items-center
          space-x-2
          md:space-y-0
          space-y-2
          md:flex-row
          flex-col
        "
      >
        <div class="flex flex-row items-center space-x-2">
          <img
            v-if="currentUser.photo"
            class="h-10 w-10 rounded-full ring-2 ring-white hover:shadow-lg"
            v-bind:src="currentUser.photo"
            alt=""
          />
          <t-avatar
            v-else
            class="h-10 w-10 hover:shadow-lg"
            :username="currentUser.fullname"
          ></t-avatar>
        </div>
      </div>
    </div>

    <section class="bg-gray-100 pt-8 pb-32 relative">
      <div class="container mx-auto px-2 pt-4 pb-12 text-gray-800">
        <h1
          class="
            w-full
            my-2
            text-5xl
            font-bold
            leading-tight
            text-center text-gray-800
          "
        >
          Ваше новое окружение
        </h1>
        <div class="flex flex-col items-center justify-items-center py-2">
          <h2>Назовите его:</h2>
          <div class="w-80 py-4">
            <t-input placeholder="Наименование..." v-model="spacename" />
          </div>

          <h2>... и выберите тариф:</h2>
          <!-- <div
            class="
              flex flex-col
              md:space-x-4 md:space-y-0
              space-y-4
              md:flex-row
              py-4
            "
          >
            <label class="flex items-center">
              <t-radio name="seltarifs" :value="1" v-model="tarif" checked />
              <span class="ml-2 text-lg">Ознакомление</span>
            </label>
            <label class="flex items-center">
              <t-radio name="seltarifs" :value="2" v-model="tarif" />
              <span class="ml-2 text-lg">Малый бизнес</span>
            </label>
            <label class="flex items-center">
              <t-radio name="seltarifs" :value="3" v-model="tarif" />
              <span class="ml-2 text-lg">КОРП</span>
            </label>
          </div> -->
          <t-rich-select
            class="py-4 w-56"
            v-model="tarif"
            :options="[
              { value: 1, text: 'Ознакомление' },
              { value: 2, text: 'Малый бизнес' },
              { value: 3, text: 'КОРП' }
            ]"
            placeholder="Тарифный план"
            hideSearchBox
          ></t-rich-select>

          <div class="flex flex-row items-center justify-items-center">
            <button @click="decTarif" class="p-2">
              <i
                class="nsparrow nspleft border-gray-500 hover:border-blue-500"
              ></i>
            </button>
            <div class="flex flex-row space-x-0">
              <transition
                name="slide"
                enter-class="transform origin-center scale-x-0"
                enter-to-class="transform origin-center scale-x-full"
                leave-to-class="transform origin-center scale-x-0"
                enter-active-class="transition-transform duration-100 ease-out"
                leave-active-class="transition-transform duration-100 ease-out"
                mode="out-in"
              >
                <div
                  class="flex flex-col transform origin-center scale-x-full"
                  v-if="tarif == 1"
                  key="tfree"
                >
                  <div
                    class="
                      bg-gradient-to-b
                      from-white
                      to-purple-100
                      border
                      rounded-lg
                      py-2
                      px-6
                    "
                  >
                    <ul class="text-center text-sm">
                      <li class="border-b py-2">
                        <span class="text-pink-600">1</span> окружение
                      </li>
                      <li class="border-b py-2">
                        <span class="text-pink-600">3</span> пользователя
                      </li>
                      <li class="border-b py-2">
                        <span class="text-pink-600">без</span> хранения файлов
                      </li>
                      <li class="border-b py-2">
                        <span class="text-pink-600">∞</span> проекты
                      </li>
                      <li class="border-b py-2">
                        <span class="text-pink-600">∞</span> заказчики
                      </li>
                      <li class="border-b py-2">
                        до <span class="text-pink-600">30</span> задач
                      </li>
                      <li
                        class="border-b py-2"
                        title="через месяц после создания задача будет удалена вместе со всем содержимым"
                      >
                        задачи живут <span class="text-pink-600">1 месяц</span>
                      </li>
                    </ul>
                    <div
                      class="
                        w-full
                        pt-4
                        text-lg text-gray-600
                        font-bold
                        text-center
                      "
                    >
                      0 р.
                    </div>
                  </div>
                  <button
                    class="
                      mx-auto
                      lg:mx-0
                      hover:underline
                      bg-blue-300
                      text-gray-800
                      font-bold
                      rounded-full
                      my-6
                      py-4
                      px-8
                      shadow-lg
                      focus:outline-none focus:shadow-outline
                      transform
                      transition
                      hover:scale-105
                      duration-300
                      ease-in-out
                    "
                    @click="runSpace"
                  >
                    Попробовать
                  </button>
                </div>

                <div
                  class="flex flex-col transform origin-center scale-x-full"
                  v-if="tarif == 2"
                  key="tsmb"
                >
                  <div
                    class="
                      bg-gradient-to-b
                      from-white
                      to-green-100
                      border
                      rounded-lg
                      py-2
                      px-6
                    "
                  >
                    <ul class="text-center text-sm">
                      <li class="border-b py-2">
                        <span class="text-pink-600">∞</span> окружения
                      </li>
                      <li class="border-b py-2">
                        <span class="text-pink-600">∞</span> пользователи
                      </li>
                      <li class="border-b py-2">
                        <span class="text-pink-600">∞</span> файлы
                      </li>
                      <li class="border-b py-2">
                        <span class="text-pink-600">∞</span> проекты
                      </li>
                      <li class="border-b py-2">
                        <span class="text-pink-600">∞</span> заказчики
                      </li>
                      <li class="border-b py-2">
                        <span class="text-pink-600">∞</span> задачи
                      </li>
                      <li
                        class="border-b py-2"
                        title="через год после создания задача будет удалена вместе со всем содержимым"
                      >
                        задачи живут <span class="text-pink-600">1 год</span>
                      </li>
                      <li class="border-b py-2">
                        <span class="text-pink-600">нет</span> доступа к API
                      </li>
                    </ul>
                    <div
                      class="
                        w-full
                        pt-4
                        text-lg text-gray-600
                        font-bold
                        text-center
                      "
                    >
                      1000 р.
                      <span class="text-base">/ мес / чел</span>
                    </div>
                  </div>
                  <button
                    class="
                      mx-auto
                      lg:mx-0
                      hover:underline
                      bg-blue-300
                      text-gray-800
                      font-bold
                      rounded-full
                      my-6
                      py-4
                      px-8
                      shadow-lg
                      focus:outline-none focus:shadow-outline
                      transform
                      transition
                      hover:scale-105
                      duration-300
                      ease-in-out
                    "
                    @click="runSpace"
                  >
                    Оплатить
                  </button>
                </div>

                <div
                  class="flex flex-col transform origin-center scale-x-full"
                  v-if="tarif == 3"
                  key="tcorp"
                >
                  <div
                    class="
                      bg-gradient-to-b
                      from-white
                      to-blue-100
                      border
                      rounded-lg
                      py-2
                      px-6
                    "
                  >
                    <ul class="text-center text-sm">
                      <li class="border-b py-2">
                        <span class="text-pink-600">∞</span> окружения
                      </li>
                      <li class="border-b py-2">
                        <span class="text-pink-600">∞</span> пользователи
                      </li>
                      <li class="border-b py-2">
                        <span class="text-pink-600">∞</span> файлы
                      </li>
                      <li class="border-b py-2">
                        <span class="text-pink-600">∞</span> проекты
                      </li>
                      <li class="border-b py-2">
                        <span class="text-pink-600">∞</span> заказчики
                      </li>
                      <li class="border-b py-2">
                        <span class="text-pink-600">∞</span> задачи
                      </li>
                      <li class="border-b py-2">
                        <span class="text-pink-600">Доступ</span> к API
                      </li>
                    </ul>
                    <div
                      class="
                        w-full
                        pt-4
                        text-lg text-gray-600
                        font-bold
                        text-center
                      "
                    >
                      1500 р.
                      <span class="text-base">/ мес / чел</span>
                    </div>
                  </div>
                  <button
                    class="
                      mx-auto
                      lg:mx-0
                      hover:underline
                      bg-blue-300
                      text-gray-800
                      font-bold
                      rounded-full
                      my-6
                      py-4
                      px-8
                      shadow-lg
                      focus:outline-none focus:shadow-outline
                      transform
                      transition
                      hover:scale-105
                      duration-300
                      ease-in-out
                    "
                    @click="runSpace"
                  >
                    Оплатить
                  </button>
                </div>
              </transition>
            </div>
            <button @click="incTarif" class="p-2">
              <i
                class="nsparrow nspright border-gray-500 hover:border-blue-500"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Logo from '@/components/Logo.vue'
import { catchErr } from '@/error'
import users from '@/api/users'

export default {
  components: { Logo },
  computed: {
    ...mapGetters(['isAdmin', 'currentUser'])
  },
  data() {
    return {
      spacename: '',
      tarif: 1
    }
  },
  methods: {
    ...mapActions(['whoami']),
    greetText() {
      var d = new Date()
      var time = d.getHours()

      if (time < 12) {
        return 'Доброе утро, ' + this.currentUser.fullname
      } else if (time < 17) {
        return 'Добрый день, ' + this.currentUser.fullname
      } else {
        return 'Добрый вечер, ' + this.currentUser.fullname
      }
    },
    formatDateDisplay(date) {
      let options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }
      let d = new Date(date)
      // if (this.dateDisplay === "toDateString") return d.toLocaleDateString();
      // if (this.dateDisplay === "toLocaleDateString")
      return d.toLocaleDateString('ru-RU', options)
      // d = new Date();
      // return d.toLocaleDateString("ru-RU", options);
    },
    incTarif() {
      if (this.tarif == 3) {
        this.tarif = 1
        return
      }
      this.tarif = this.tarif + 1
    },
    decTarif() {
      if (this.tarif == 1) {
        this.tarif = 3
        return
      }
      this.tarif = this.tarif - 1
    },
    runSpace() {
      users
        .genSpace({
          title: this.spacename,
          tarif: this.tarif
        })
        .then(() => {
          //TODO: navigate to payment page
          this.whoami()
        })
        .catch((error) => {
          catchErr(error)
        })
    }
  }
}
</script>

<style>
.nsparrow {
  border: solid;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
}

.nspright {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.nspleft {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
</style>
