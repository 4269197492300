<template>
  <div class="md:max-w-5xl mx-auto w-full h-full">
    <t-card>
      <!-- header="Заказчики" variant="blackboldcaption" -->
      <div class="flex-1 w-full md:rounded-lg bg-white min-h-full space-y-2">
        <t-rich-select
          v-model="selected"
          :options="selAllCustomers"
          placeholder="Заказчики..."
          noResultsText="Не найдено..."
          searchBoxPlaceholder="Найти..."
        >
          <template slot="dropdownDown" slot-scope="{ query }">
            <div class="text-center">
              <button
                type="button"
                class="
                  block
                  w-full
                  px-3
                  py-2
                  text-white
                  bg-blue-700
                  border
                  hover:bg-blue-800
                  md:rounded
                "
                @click="createOption(query)"
              >
                Создать {{ query }}
              </button>
            </div>
          </template>
        </t-rich-select>
        <div v-show="editing" class="flex flex-col space-y-2 ml-4">
          <t-input-group label="Наименование">
            <t-input placeholder="Наименование..." v-model="current.name" />
          </t-input-group>
          <t-input-group label="Описание">
            <t-textarea
              v-model="current.description"
              placeholder="Описание..."
            />
          </t-input-group>
          <div class="flex justify-end items-center space-x-2">
            <t-button variant="secondary" @click="cancelCurrent"
              >Отмена</t-button
            >
            <t-button @click="saveCustomer">Сохранить</t-button>
          </div>
        </div>
      </div>
    </t-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import customers from '@/api/customers'
import { generateUUID } from './const'
import { catchErr } from '@/error'

export default {
  data() {
    return {
      selected: null,
      current: {},
      editing: false
    }
  },
  created() {
    this.fetchData()
  },
  watch: {
    selected: 'loadCurrent'
  },
  computed: {
    ...mapGetters(['selAllCustomers'])
  },
  methods: {
    ...mapActions(['fetchAllCustomers']),
    fetchData() {
      this.fetchAllCustomers()
    },
    cancelCurrent() {
      this.current = {
        id: '',
        name: '',
        description: ''
      }
      this.selected = null
      this.editing = false
    },
    loadCurrent() {
      if (this.selected) {
        customers
          .getById(this.selected)
          .then((response) => {
            this.current = response.data
            this.editing = true
          })
          .catch((error) => {
            if (error.response.status == 404) {
              this.current = {
                id: this.selected,
                name: '',
                description: ''
              }
              this.editing = true
            } else {
              catchErr(error)
            }
          })
      } else {
        this.current = {
          id: '',
          name: '',
          description: ''
        }
        // this.editing = true
      }
    },
    createOption(text) {
      this.current = {
        id: generateUUID(),
        name: text,
        description: ''
      }
      this.selected = this.current.id
      this.editing = true
    },
    saveCustomer() {
      let saveData = {}
      for (let k in this.current) saveData[k] = this.current[k]
      customers
        .save(saveData)
        .then((response) => {
          this.current = response.data
          this.editing = false
          this.fetchAllCustomers()
          this.$busEmit('flash', 'Заказчик сохранен')
        })
        .catch((error) => {
          catchErr(error)
        })
    }
  }
}
</script>
