import http from './http'

const users = {
  getAll: () => http.get('/users'),
  saveCurrentSpaceId: (uid, spid) =>
    http.post(`/users/${uid}/currentspace/${spid}`),
  genSpace: (sp) => http.post(`/users/genspace`, sp)
}

export default users
