<template>
  <div class="md:py-1 md:-mt-4 py-8 justify-center md:flex">
    <div
      class="
        bg-gray-100
        py-4
        shadow
        overflow-y-auto overflow-x-hidden
        border-t-2
        h-full
        border-sky-600
        md:w-1/2
        flex
        justify-center
      "
      style="min-height: 100px"
    >
      <article class="px-4 py-2 bg-gray-100 prose">
        <h1 class="text-gray-800 text-sm">Справка</h1>
        <nav>
          <h2>Содержание</h2>
          <ul>
            <li>
              <a href="#t1" class="text-blue-900"
                >Тесная интеграция с Telegram</a
              >
            </li>
            <li><a href="#t2" class="text-blue-900">Бот TenderFlow</a></li>
            <li><a href="#t3" class="text-blue-900">Администраторы</a></li>
            <li><a href="#t4" class="text-blue-900">Окружения</a></li>
            <li>
              <a href="#t5" class="text-blue-900">Выбор окружения для работы</a>
            </li>
            <li>
              <a href="#t6" v-if="isAdmin" class="text-blue-900"
                >Добавление пользователя, выдача прав, включение в окружение</a
              >
            </li>
            <li>
              <a href="#t7" v-if="isAdmin" class="text-blue-900"
                >Редактирование списков проектов и заказчиков</a
              >
            </li>
            <li>
              <a href="#t8" class="text-blue-900"
                >Фильтрация задач на доске Канбан</a
              >
            </li>
            <li><a href="#t9" class="text-blue-900">Создание задачи</a></li>
            <li>
              <a href="#t10" class="text-blue-900">Кому доступна задача?</a>
            </li>
            <li><a href="#t11" class="text-blue-900">Мои задачи</a></li>
            <li>
              <a href="#t12" class="text-blue-900">Изменение статуса задачи</a>
            </li>
            <li><a href="#t13" class="text-blue-900">Исполнитель задачи</a></li>
            <li><a href="#t14" class="text-blue-900">Проект</a></li>
            <li>
              <a href="#t15" class="text-blue-900">Этап проекта задачи</a>
            </li>
            <li>
              <a href="#t16" class="text-blue-900">Работа с файлами задачи</a>
            </li>
            <li><a href="#t17" class="text-blue-900">Комментарии</a></li>
            <li><a href="#t18" class="text-blue-900">Напоминания</a></li>
            <li><a href="#t19" class="text-blue-900">Встречи</a></li>
          </ul>
        </nav>

        <h2 id="t1">Тесная интеграция с Telegram</h2>
        <p class="text-justify">
          Для работы в системе Вам (и всем, кто будет работать в системе)
          необходимо быть зарегистрированным в мессенджере Telegram. Вход в
          систему будет возможен только при наличии аккаунта Telegram.
        </p>
        <h2 id="t2">Бот TenderFlow</h2>
        <p>
          Система будет уведомлять Вас о разных событиях через диалог с
          <a href="https://t.me/tender_flow_bot" class="text-blue-900"
            >ботом в Telegram</a
          >. Когда вы впервые будете входить в систему, не забудьте оставить
          включенной галочку
          <b>"Разрешить Tender Flow Bot писать Вам в Telegram"</b>, иначе Вы не
          будете получать уведомлений и не сможете полноценно пользоваться
          системой.
          <img src="/img/firstlogin.png" />
        </p>
        <p>
          Чтобы Вы смогли успешно войти в систему, администратор (или владелец
          системы) должен сначала зарегистрировать Вас в системе и выдать права.
          Если вы логинитесь в систему, и при этом ничего не происходит -
          обартитесь к своему администратору. Если это не помогло, напишите в
          <a href="https://t.me/tsov_pro" class="text-blue-900">техподдержку</a
          >.
        </p>

        <h2 id="t3">Администраторы</h2>
        <p>
          В каждом окружении должен быть как минимум один администратор,
          которого устанавливают владельцы системы. В его задачи входит:
          управление бэклогом задач, группами доступа пользователей в проекты и
          задачи, редактирование списка проектов и заказчиков, назначением
          проекта в задачу, смена этапа проекта задачи. Все другие пользователи,
          которые не являются администраторами,<b>не могут</b> добавлять новых
          пользователей, создавать новые проекты, редактировать список
          заказчиков, выбирать проект в задачу, изменять этап проекта задачи.
          Все остальное им доступно.
        </p>

        <h2 id="t4">Окружения</h2>
        <p>
          Пользовательские окружения - это отдельные области хранения данных о
          задачах по проектам клиентов, с которыми ведется работа ограниченным
          кругом пользователей . Каждое окружение
          <b>изолировано от других</b> и объединяет в себе задачи с их файлами,
          списки заказчиков, проектов и пользователей окружения. Оплата доступа
          к сервису TenderFlow производится для каждого окружения отдельно.
          Данные Вашего окружения физически отделены и недоступны пользователям
          других окружений.
        </p>
        <p>
          Каждое окружение управляется одним (или более) администратором.
          Администратор может создавать множество окружений и добавлять в них
          пользователей, после того, как был оплачен доступ к нужному окружению.
          Владельцы системы тоже могут создавать окружения заранее, и назначать
          в них администраторов.
        </p>
        <p v-if="isAdmin">
          Обычно администратору не требуется сразу создавать новое окружение, он
          может добавлять пользователей в то окружение, где уже сам является
          администратором.
        </p>

        <h2 id="t5">Выбор окружения для работы</h2>
        <p>
          Если Вам доступно несколько окружений, то в меню настроек Вы можете
          выбрать то окружение, с которым в данный момент будете работать.Для
          перехода в меню настроек нужно нажать в правом верзнем углу главной
          страницы на свой аватар, если картинка профиля в вашем профиле
          Telegram была установлена, или на знак шестеренки, если аватара у Вас
          нет.
          <img src="/img/selspace.gif" />
        </p>

        <div v-if="isAdmin">
          <h2 id="t6">
            Добавление пользователя, выдача прав, включение в окружение
          </h2>
          <p>
            Администратор (или владелец системы) могут добавлять пользователей,
            разрешая им работу с определенной ролью в рамках выбранного
            окружения. Для этого, необходимо в меню
            <a href="https://t.me/tender_flow_bot" class="text-blue-900"
              >бота</a
            >
            перейти в главное меню и выбрать пункт <b>Выдача прав доступа</b>:
            <img src="/img/selusermenu.gif" />
          </p>
          <p>
            Бот выведет список пользователей из тех окружений, где Вы являетесь
            администратором. Владельцу системы будут дополнительно показаны
            пользователи, которые зарегистрировались через бот, но еще не были
            включены ни в одно из окружений.
          </p>
          <p>
            Теперь, чтобы перейти в меню редактирования ролей и окружений
            пользователя, Вы можете либо нажать на номер пользователя в
            выведенном списке, либо переслать контакт или поделиться контактом с
            ботом:
            <img src="/img/selcontact.gif" />
            <img src="/img/sendcontact.gif" />
          </p>
          <p>
            В появившемся меню Вы можете выдать права пользователю, нажимая на
            соответствующую кнопку с названием роли. На кнопке будет появляться
            (или исчезать) символ "галочка", который означает, что нужная роль
            назначена пользователю.
          </p>
          <p>
            Кнопка <b>Доступ к окружениям</b> делает переход в меню назначения
            окружений выбранному пользователю. Установка галочки при нажатии на
            нужную кнопку окружения дает возможность пользователю выбрать это
            окружение у себя в настройках и работать с его задачами, файлами и
            т.п.
            <img src="/img/addtospace.gif" />
          </p>
          <p>
            Каждое окружение имеет <b>лимит на количество пользователей</b>,
            которые в него могут быть добавлены. Этот лимит зависит от
            оплаченного тарифа. При попытке добавления пользователя в окружение,
            если его лимит был превышен, бот сообщит Вам об этом.
          </p>
          <p>
            Администратор не может создавать новые окружения. Это может сделать
            только владелец системы. Для этого, находясь в меню редактирования
            окружений он вводит название нового окружения (вместо нажатия на
            кнопку существующего). Далее он указывает квоту на количество
            пользователей в окружении в соответствии с оплаченным тарифом.
          </p>
          <p>
            Кнопка <b>Удалить пользователя</b> запрещает пользователю доступ в
            систему, и в дальнейшем он пропадает из Вашего списка. Чтобы его
            вернуть, придется завести его как нового, при этом, все его старые
            задачи ему уже больше не будут видны! С осторожностью нажимайте на
            эту кнопку!
          </p>

          <h2 id="t7">Редактирование списков проектов и заказчиков</h2>
          <p>
            <b>Для выбранного в настройках окружения</b>, Администратор
            настраивает список заказчиков в меню настроек. Для перехода в меню
            настроек нужно нажать в правом верзнем углу главной страницы на свой
            аватар, если картинка профиля в вашем профиле Telegram была
            установлена, или на знак шестеренки, если аватара у Вас нет.
            <img src="/img/editcustproj.gif" />
          </p>
          <p>
            При редактировании проектов есть возможность указать ограниченный
            перечень пользователей, которые смогут работать с задачами по этому
            проекту. Если пользователи не указаны, то будет использоваться
            аналогичный перечень из настройки самой задачи, либо задачи по
            такому проекту будут доступны всем пользователям окружения.
          </p>
          <p>
            Обычным пользователям редактирование проектов и заказчиков
            недоступно.
          </p>
        </div>
        <h2 id="t8">Фильтрация задач на доске Канбан</h2>
        <p>
          Вы можете воспользоваться фильтрами в правом верхнем углу, чтобы
          ограничить отображаемые на доске задачи определенными проектами,
          этапами или исполнителями.
          <img src="/img/filters.gif" />
        </p>
        <h2 id="t9">Создание задачи</h2>
        <p>
          Задачу можно создать, нажав на значок <b>+</b> в нужной колонке
          нужного этапа доски. В большинстве случаев, вы будете создавать новые
          задачи в колонке "Бэклог". Бэклог - это список задач, которые еще не
          взяты в работу, но в будущем их нужно будет сделать.
          <img src="/img/createtask.gif" />
        </p>
        <p>
          В колонке "Бэклог" показываются задачи, записанные не раньше, чем 14
          дней назад. Более старые задачи отображаются в полном бэклоге, который
          доступен при нажатии на переключатель "Весь бэклог".
        </p>
        <img src="/img/createtask.gif" />

        <h2 id="t10">Кому доступна задача?</h2>
        <p>
          С каждой отдельной задачей могут работать только те пользователи,
          которые указаны в перечне пользователей проекта, либо в перечне
          пользователей самой задачи на закладке <b>Статус и участники</b>,
          раздел <b>Группа доступа к задаче</b>. Администраторы работают со
          всеми задачами окружения.
        </p>

        <h2 id="t11">Мои задачи</h2>
        <p>
          В меню бота у каждого пользователя ситстемы есть кнопка
          <b>Мои задачи</b>, при нажатии на которую пользователь получит список
          задач, к которым имеет доступ, со ссылками на них. Щелкнув по ссылке
          он может открыть нужную задачу прямо в Telegram или в браузере.
        </p>

        <h2 id="t12">Изменение статуса задачи</h2>
        <p>
          По мере работы над задачей, ее можно переместить в нужную колонку
          доски. При этом, сменится ее статус. Так же статус можно поменять в
          самой задаче, на закладке <b>Статус и участники</b>, раздел
          <b>Этап</b>.
        </p>

        <p>
          Если задача попадает в колонку "Завершено", то у нее устанавливается
          признак завершенной задачи. Уведомление о завершении отправляется
          администратору. Такие задачи видны на доске в течение 14 дней с
          момента завершения. Если нужно посмотреть все завершенные задачи в
          этой колонке, то в настройках нужно поставить галочку "Показывать
          завершенные задачи старше 14 дней".
        </p>
        <img src="/img/movetask.gif" />

        <h2 id="t13">Исполнитель задачи</h2>
        <p>
          В каждой задаче есть возможность указывать исполнителя, который в
          данный момент работает над задачей. Это можно сделать на закладке
          <b>Статус и участники</b>, раздел <b>Назначена исполнителю</b>. В
          момент указания нового исполнителя, новому исполнителю в Telegram
          придет оповещение со ссылкой на задачу, из которого он сможет ее
          открыть.
        </p>

        <h2 id="t14">Проект</h2>
        <p>
          Каждая задача может относиться к проекту Вашего заказчика.
          Администратор может установить соответствующее поле в задаче на
          закладке <b>Статус и участники</b>, раздел <b>Проект</b>.
        </p>

        <h2 id="t15">Этап проекта задачи</h2>
        <p>
          Базовый набор этапов всех проектов в системе один в одном конкретном
          окружении. В рамках одного окружения может выполняться множество
          проектов с одним и тем же набором стандартных этапов. По мере работы
          над задачей, ее можно переместить в следующий этап проекта. Это может
          сделать только администратор, переместив задачу на доске или изменив
          этап на закладке
          <b>Статус и участники</b>, раздел <b>Этап</b>.
        </p>

        <h2 id="t16">Работа с файлами задачи</h2>
        <p>
          На закладке <b>Файлы</b> все, кто имеет право на работу с задачей,
          могут прикреплять файлы, открывать их, прикреплять ссылки на документы
          Google, совместно редактировать документы Google:
        </p>
        <img src="/img/taskfiles.gif" />

        <h2 id="t17">Комментарии</h2>
        <p>
          На закладке <b>Комментарии</b> все участники по задаче могут оставлять
          свои комментарии.
        </p>

        <h2 id="t18">Напоминания</h2>
        <p>
          На закладке <b>Напоминания</b> можно добавить напоминание или
          отредактировать ранее добавленые. В установленную дату и время
          напоминание пришлет бот через Telegram. Получить напоминание может
          исполнитель, адмиистраторы или все участники задачи, в зависимости от
          настройки в разделе <b>Группа получателей</b>.
        </p>

        <h2 id="t19">Встречи</h2>
        <p>
          На закладке <b>Встречи</b> Администратор может для каждой задачи
          указать ссылку на общедоступный календарь, в котором всеми участниками
          планироваться встречи. Этот календарь может быть одним и тем же у
          разных задач, тогда все встречи по всем таким задачам будут видны в
          одном и том же календаре.
        </p>
        <p v-if="isAdmin">
          Создать и получить ссылку на календарь можно вот так:
          <img src="/img/tasknewcal.gif" />
          Чтобы получить ссылку для уже существующего календаря необходимо
          просто зайти в его настройки:
          <img src="/img/taskexistscal.gif" />
        </p>
        <p>
          Чтобы открыть календарь задачи, нужно нажать на ссылку
          <b>Открыть календарь задачи</b>
        </p>
        <img src="/img/taskopencal.gif" />
      </article>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isAdmin'])
  }
}
</script>
