var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"time-picker",staticClass:"time-picker flex flex-1",class:{ inline: _vm.inline, 'is-dark': _vm.dark, 'with-border': !_vm.onlyTime },style:([{ height: (_vm.height + "px") }])},_vm._l((_vm.columns),function(column){return _c('div',{key:column.type,ref:column.type,refInFor:true,staticClass:"time-picker-column flex-1 flex flex-col text-center",class:[("time-picker-column-" + (column.type))],on:{"scroll":function($event){_vm.noScrollEvent
        ? null
        : column.type === 'hours'
        ? _vm.onScrollHours($event)
        : column.type === 'minutes'
        ? _vm.onScrollMinutes($event)
        : _vm.onScrollApms($event)}}},[_c('div',[_c('div',{staticClass:"before",style:([_vm.columnPadding])}),_vm._l((column.items),function(item){return _c('button',{key:item.item,staticClass:"time-picker-column-item flex items-center justify-center",class:{
          active: _vm.isActive(column.type, item.value),
          disabled: item.disabled
        },attrs:{"type":"button","tabindex":"-1"},on:{"click":function($event){item.disabled ? null : _vm.setTime(item.value, column.type)}}},[_c('span',{staticClass:"time-picker-column-item-effect",style:(_vm.styleColor)}),_c('span',{staticClass:"time-picker-column-item-text flex-1"},[_vm._v(" "+_vm._s(item.item)+" ")])])}),_c('div',{staticClass:"after",style:([_vm.columnPadding])})],2)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }