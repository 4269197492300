<template>
  <div class="md:py-1 md:-mt-4 py-8">
    <div class="md:flex justify-start text-center md:pb-0 pb-2 space-x-4">
      <div class="flex p-1 md:w-1/6 space-x-4">
        <label class="flex items-center cursor-pointer">
          <t-radio name="pageswitch" value="kanban" checked />
          <span class="ml-1 text-sm">Канбан</span>
        </label>
        <label
          class="flex items-center ml-2 cursor-pointer"
          @click.prevent="$router.push('/tasklist')"
        >
          <t-radio name="pageswitch" value="tasklist" />
          <span class="ml-1 text-sm">Список</span>
        </label>
      </div>
      <div class="md:flex justify-end w-full">
        <div class="p-1 md:w-1/6">
          <t-rich-select
            v-model="filterProjectId"
            :options="projectsCustomers"
            value-attribute="id"
            text-attribute="name"
            placeholder="Проекты..."
            noResultsText="Не найдено..."
            searchBoxPlaceholder="Найти..."
            clearable
          ></t-rich-select>
        </div>
        <div class="p-1 md:w-1/6">
          <t-rich-select
            v-model="filterStage"
            :options="selStages"
            placeholder="Этапы..."
            noResultsText="Не найдено..."
            searchBoxPlaceholder="Найти..."
            clearable
          ></t-rich-select>
        </div>
        <div class="p-1 md:w-1/6">
          <t-rich-select
            v-model="filterUser"
            :options="selAllUsers"
            placeholder="Назначено..."
            noResultsText="Не найдено..."
            searchBoxPlaceholder="Найти..."
            clearable
          ></t-rich-select>
        </div>
      </div>
    </div>
    <t-accordion-item
      v-for="(stage, indexStage) in allStages.filter((t) => {
        return t === filterStage || !filterStage
      })"
      v-bind:key="indexStage"
      class="border-b border-gray-200 bg-gray-200 pb-4 text-gray-800 w-full"
      :title="stage"
      isActive="true"
      textCenter="true"
    >
      <div
        class="
          md:flex
          -mx-4
          block
          overflow-x-auto
          md:overflow-y-hidden md:max-h-screen
          justify-items-stretch
        "
        style="min-height: 8em"
      >
        <div
          v-for="(board, index) in allBoards"
          v-bind:key="index"
          class="
            md:w-1/5
            flex-shrink-0 flex flex-col
            justify-items-stretch
            md:pb-0
            pb-2
          "
        >
          <div class="bg-gray-100 shadow border-t-2 h-full border-blue-300">
            <div
              class="
                flex
                justify-between
                items-center
                px-4
                py-2
                bg-gray-100
                text-xs
              "
            >
              <h2 class="font-medium text-gray-800">
                <t-tag tag-name="span" variant="smallbadge">{{
                  tasksByProjectBoardStage(
                    filterProjectId,
                    board,
                    stage,
                    filterUser
                  ).length
                }}</t-tag>
                {{ board }}
              </h2>
              <a
                @click.prevent="newTaskModal(board, stage)"
                href="#"
                class="
                  inline-flex
                  items-center
                  font-medium
                  text-gray-500
                  hover:text-blue-600
                "
              >
                <svg
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 4v16m8-8H4"
                  />
                </svg>
                Задача
              </a>
            </div>

            <div
              class="
                p-4
                h-full
                overflow-y-auto
                flex flex-col
                justify-items-stretch
                rounded-lg
                space-y-3
              "
              v-on:dragover.capture="onDragOver($event)"
              v-on:drop.capture.stop.prevent="onDrop($event, board, stage)"
              v-on:dragenter.capture.stop.prevent="onDragEnter($event)"
            >
              <div
                v-for="t in tasksByProjectBoardStage(
                  filterProjectId,
                  board,
                  stage,
                  filterUser
                )"
                v-bind:key="t.id"
                :ref="t.id"
                class="bg-white p-3 border shadow-sm"
                :class="{
                  'bg-red-100': t.currentUserFlag == 0,
                  'bg-yellow-100': t.currentUserFlag == 1,
                  'bg-blue-100': t.currentUserFlag == 2,
                  'bg-green-100': t.currentUserFlag == 3
                  // 'bg-gray-100': t.currentUserFlag == 4
                }"
                draggable="true"
                v-on:dragstart.self="onDragStart($event, t.id)"
              >
                <div class="flex flex-row justify-between">
                  <a
                    v-text="t.name"
                    class="
                      text-gray-800
                      no-underline
                      hover:underline
                      cursor-pointer
                      pb-1
                      flex
                      text-sm
                    "
                    herf="#"
                    @click="openEditTask(t)"
                  ></a>

                  <t-select-flag
                    class="text-sm"
                    v-bind:value="t.currentUserFlag"
                    v-on:input="setFlag(t, $event)"
                  ></t-select-flag>
                </div>

                <div
                  class="
                    text-gray-800 text-xs
                    md:flex
                    justify-between
                    items-center
                  "
                >
                  <div>
                    <span
                      v-if="t.assignedId == currentUser.id"
                      class="pr-1"
                      :class="
                        taskExpired(t) ? 'text-red-400' : 'text-green-400'
                      "
                      >&bull;</span
                    >
                    {{ t.assigned.fullname }}
                  </div>
                  <div>{{ t.project.name }}</div>
                </div>
                <div
                  class="
                    text-gray-500 text-xs
                    md:flex
                    justify-between
                    items-center
                  "
                >
                  <div v-text="formatDateDisplay(t.endDate)"></div>
                  <div>{{ t.project.customer.name }}</div>
                  <div>#{{ t.number }}</div>
                </div>
              </div>
              <div class="p-4"></div>
            </div>
          </div>
        </div>
      </div>
    </t-accordion-item>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { generateUUID } from './const'
import tasks from '@/api/tasks'
import { catchErr } from '@/error'

export default {
  // beforeMount() {
  //   console.log(`beforeMount`)
  // },
  created() {
    // console.log(`created`)
    let sess = sessionStorage.getItem('sess')
    if (sess) {
      // console.log(sess)
      let sessobj = JSON.parse(sess)
      this.filterProjectId = sessobj.filterProjectId
      this.filterStage = sessobj.filterStage
      this.filterUser = sessobj.filterUser
    }
  },
  mounted() {
    this.fetchData()
    // console.log('mounted')
  },
  beforeDestroy() {
    // console.log('beforeDestroy')
    let sess = JSON.stringify({
      filterProjectId: this.filterProjectId,
      filterStage: this.filterStage,
      filterUser: this.filterUser
    })
    // console.log(sess)
    sessionStorage.setItem('sess', sess)
  },
  watch: {
    // при изменениях маршрута запрашиваем данные снова
    $route: 'loadTasksData'
  },

  data() {
    return {
      filterProjectId: null,
      filterStage: null,
      filterUser: null,
      lastDragEnterTarget: null
    }
  },

  computed: {
    ...mapGetters([
      'currentUser',
      'allBoards',
      'allStages',
      'projectsCustomers',
      'selStages',
      'allTasks',
      'selAllUsers',
      'isAdmin'
    ])
  },

  methods: {
    ...mapActions([
      'fetchTaskClassifiers',
      'fetchAllProjects',
      'fetchAllTasks',
      'fetchAllUsers',
      'moveTaskStageBoard'
    ]),

    fetchData() {
      this.fetchTaskClassifiers()
      if (this.isAdmin) {
        this.fetchAllUsers()
      }
      this.loadTasksData()
    },

    loadTasksData() {
      this.fetchAllProjects()
      this.fetchAllTasks()
    },

    tasksByProjectBoardStage(filterProjectId, board, stage, filterUser) {
      return this.allTasks.filter((t) => {
        return (
          t.boardId === board &&
          t.stageId === stage &&
          (t.projectId === filterProjectId || !filterProjectId) &&
          (t.assignedId === filterUser || !filterUser)
        )
      })
    },

    newTaskModal(board, stage) {
      // Новая задача
      this.$router.push({
        path: `/task/${generateUUID()}`,
        query: {
          boardId: board,
          stageId: stage,
          projectId: this.filterProjectId ? this.filterProjectId : ''
        }
      })
    },

    openEditTask(t) {
      this.$router.push({
        path: `/task/${t.id}`
      })
    },

    taskExpired(t) {
      if (t.endDate) {
        let end = new Date(t.endDate)
        end.setHours(23, 59, 59, 999)
        return end < new Date()
      }
      return false
    },

    formatDateDisplay(date) {
      if (!date) {
        return 'когда нибудь'
      }
      let options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }
      let d = new Date(date)
      return d.toLocaleDateString('ru-RU', options)
    },

    onDragStart(event, uuid) {
      event.dataTransfer.setData('text/plain', uuid)
    },

    onDragOver(event) {
      event.preventDefault()
      return false
    },

    onDragEnter(event) {
      let lt = this.lastDragEnterTarget
      if (lt && lt != event.currentTarget) {
        lt.classList.remove('bg-gray-200')
      }
      event.currentTarget.classList.add('bg-gray-200')
      this.lastDragEnterTarget = event.currentTarget
    },

    onDrop(event, boardId, stageId) {
      // console.log(event.currentTarget)

      event.currentTarget.classList.remove('bg-gray-200')
      this.lastDragEnterTarget = null

      const id = event.dataTransfer.getData('text')

      let t = this.allTasks.find((t) => {
        return t.id === id
      })

      if (t.boardId != boardId || t.stageId != stageId) {
        this.moveTaskStageBoard({ id, boardId, stageId })
      }
    },

    setFlag(t, v) {
      tasks
        .postFlag(t.id, { flag: v })
        .then(() => {
          t.currentUserFlag = v
        })
        .catch((error) => {
          if (error.response.status != 404) {
            catchErr(error)
          }
        })
    }
  }
}
</script>
