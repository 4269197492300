<template>
  <div class="md:py-1 md:-mt-4 py-8 justify-center md:flex">
    <div
      class="
        bg-gray-100
        py-4
        shadow
        overflow-y-auto overflow-x-hidden
        border-t-2
        h-full
        border-sky-600
        md:w-1/2
        flex
        justify-center
      "
      style="min-height: 100px"
    >
      <article class="px-4 py-2 bg-gray-100 prose">
        <h1 class="text-gray-800 text-sm">
          Пользовательское соглашение Tender Flow
        </h1>
        <h2>1. Термины</h2>
        <ol>
          <li>
            <b>Сервис</b> — сайт tender-flow.ru, на котором размещен
            информационно-технологический сервис, включающий в себя программы
            для ЭВМ (далее Программы), работающие через Интернет, и
            дополнительные информационные материалы.
          </li>
          <li>
            <b>Администратор Сервиса</b> — ООО "РЭМ-СОФТ", обеспечивающая работу
            сервиса.
          </li>
          <li>
            <b>Исполнитель</b> — ООО "РЭМ-СОФТ", оказывающая услуги по
            подключению к Сервису, регистрации пользователей, сопровождению и
            поддержке, в соответствии с тарифным планом, размещенным на сайте
            tender-flow.ru.
          </li>
          <li>
            <b>Пользователь</b> — физическое или юридическое лицо, заключившее
            договор на использование Сервиса с Исполнителем.
          </li>
        </ol>
        <h2>2. Общие положения</h2>
        <ol>
          <li>
            Использование Сервиса регулируется настоящим Соглашением, договором
            Исполнителя, тарифными планами, опубликованными на сайте
            tender-flow.ru.
          </li>
          <li>
            Настоящее Соглашение может быть изменено без какого-либо
            специального уведомления. Новая редакция Соглашения вступает в силу
            с даты ее публикации, если иное не указано в Соглашении.
          </li>
          <li>
            После прохождения процедуры регистрации или входа с помощью внешней
            аутентификации на сайте
            <b>tender-flow.ru</b> Пользователь считается принявшим условия
            Соглашения в полном объеме, без каких-либо оговорок и исключений. В
            случае, если были внесены какие-либо изменения в Соглашение в
            порядке, предусмотренном пунктом 2.2 Соглашения, с которыми
            Пользователь не согласен, он обязан прекратить использование
            Сервиса.
          </li>
        </ol>
        <h2>3. Порядок предоставления доступа к Сервису</h2>
        <ol>
          <li>
            Доступ Пользователям к Сервису предоставляется после регистрации
            Пользователя на сайте tender-flow.ru. Объем доступа к Сервису
            определяется в соответствии с заключенным договором на использование
            Сервиса с Исполнителем и выбранным тарифным планом, опубликованным
            на сайте Исполнителя.
          </li>
          <li>
            Администратор Сервиса и Исполнитель не инициируют и не контролируют
            размещение Пользователем любой информации в процессе использования
            Сервиса, не влияют на ее содержание и целостность, а также в момент
            размещения указанной информации не знают и не могут знать, нарушает
            ли она охраняемые законом права и интересы третьих лиц,
            международные договоры и действующее законодательство Российской
            Федерации.
          </li>
          <li>
            Администратор Сервиса вправе отказать в предоставлении доступа к
            Сервису лицам, которые используют или намерены использовать его для
            каких-либо незаконных целей, а равно лицам, которые предпринимают
            попытку получить доступ способом, иным, чем описано в настоящем
            разделе.
          </li>
          <li>
            Использование Программ, входящих в Сервис, обеспечивается в
            соответствии с документацией к Программам.
          </li>
        </ol>
        <h2>4. Общие положения о работе Сервиса</h2>
        <h3>1. Администратор Сервиса</h3>
        <ol>
          <li>
            Обеспечивает работоспособность Сервиса круглосуточно, семь дней в
            неделю, включая выходные и праздничные дни. Администратор Сервиса
            вправе приостанавливать доступ к Сервису или отдельным Программам
            всем Пользователям или отдельным Пользователям по причинам
            технологического характера (профилактические работы, обновление
            программного или аппаратного обеспечения и т.п.), а также в иных
            случаях, предусмотренных настоящим Договором.
          </li>
          <li>
            Размещает данные Пользователя на собственных технологических
            ресурсах и ресурсах лицензированных операторов связи;
          </li>
          <li>
            Хранит данные пользователей сервиса изолированно друг от друга,
            благодаря применению собственных технологических средств,
            обеспечивающих режим разделения данных внутри Сервиса. Общий доступ
            к данным настраивается Администратором Сервиса или пользователями с
            административными правами только при явно выраженной воле и желанию
            самих пользователей.
          </li>
          <li>
            Предоставляет доступ к Программам Сервиса в рамках выбранного
            тарифного плана;
          </li>
          <li>
            Прилагает усилия для своевременного отражения изменений
            законодательства в пользовательском соглашении и публичном
            договоре-оферте;
          </li>
          <li>Выполняет обновление Программ;</li>
          <li>
            Обеспечивает несколько независимых уровней резервного копирования
            («бэкапов»), чтобы гарантировать сохранность ваших данных в любой
            ситуации. Выгрузки бэкапов доступны пользователям с
            административными правами.
          </li>
          <li>
            Предоставляет возможность Пользователю подключать доступ к сторонним
            сервисам и API Программ, если такая возможность предусмотрена
            функционалом Программы и включена в выбранный тарифный план;
          </li>
        </ol>
        <h3>2. Исполнитель</h3>
        <ol>
          <li>
            Использует номер телефона, электронную почту, аккаунт Телеграм
            Пользователя в качестве основного способа связи с Пользователем;
          </li>
          <li>
            Предоставляет индивидуальные консультации Пользователю по
            техническим и методическим вопросам использования Программ и
            Сервиса, если это предусмотрено выбранным тарифом;
          </li>
          <li>
            Расследует и решает проблемы, которые возникают у Пользователя по
            работе в Сервисе;
          </li>
          <li>Информирует Пользователя об изменениях функционала сервиса;</li>
          <li>
            Оказывает дополнительные услуги по согласованию с Пользователем;
          </li>
          <li>
            Взимает с Пользователя плату за Сервис в соответствии с выбранным
            тарифным планом и заключенным с Пользователем договором.
          </li>
          <li>
            Оказывает дополнительные услуги, в соответствии с тарифными планами,
            опубликованными на сайте Исполнителя.
          </li>
        </ol>
        <h3>3. Пользователь</h3>
        <ol>
          <li>
            Своевременно оплачивает услуги Исполнителя в соответствии с
            договором и выбранным тарифным планом.
          </li>
          <li>
            Не вправе передавать персональные регистрационные данные
            (идентификационные данные), предназначенные для авторизации в
            Сервисе, третьим лицам, и обязуется обеспечивать сохранность и
            конфиденциальность этих данных. Все операции, осуществленные в
            Сервисе с использованием идентификационных данных Пользователя и
            авторизации в Сервисе, считаются осуществленными Пользователем.
            Администратор Сервиса не несет ответственности за
            несанкционированное использование регистрационных
            (идентификационных) данных Пользователя третьими лицами.
          </li>
          <li>
            Уведомлен и обязан учитывать тот факт, что программное обеспечение,
            иные информационные материалы, к которым он получает доступ в рамках
            Сервиса, являются объектом исключительного права Администратора
            Сервиса и/или иных правообладателей, защищаются законодательством об
            интеллектуальной собственности и прочими соответствующими
            российскими и международными законами.
          </li>
        </ol>
        <h2>4. Ответственность</h2>
        <ol>
          <li>
            Стороны несут ответственность в соответствии с законодательством
            Российской Федерации.
          </li>
          <li>
            За нарушение установленного порядка использования материалов,
            являющихся объектами интеллектуальной собственности, к которым
            Пользователь получает доступ в ходе пользования Сервисом,
            Пользователь несет ответственность согласно действующего
            законодательства.
          </li>
          <li>
            В случае нарушения Пользователем условий Соглашения, Администратор
            Сервиса вправе приостановить доступ к Сервису до момента устранения
            Пользователем допущенных нарушений и возмещения причиненных
            Администратору Сервиса таким нарушением убытков в полном объеме
            и/или в одностороннем порядке расторгнуть Соглашение с направлением
            соответствующего уведомления Пользователю.
          </li>
          <li>
            Администратор Сервиса не несет ответственности перед Пользователем и
            третьими лицами за:
            <ol>
              <li>
                Разглашение информации, которое возникло не по вине
                Администратора Сервиса, или стало необходимым в рамках
                законодательства Российской Федерации, по запросу полномочных
                государственных органов.
              </li>
              <li>
                Любые прямые и косвенные результаты, полученные Пользователем в
                ходе использования Программы, которые предоставляются только на
                условиях «как есть». Пользователь использует информацию,
                Программу и Сервис добровольно, с условием полного принятия на
                себя всех рисков и последствий, связанных с их использованием.
              </li>
              <li>
                Совместимость программных продуктов, доступ к которым
                предоставляется в процессе оказания услуг, с конкретными
                аппаратными и программными средствами Пользователя.
              </li>
            </ol>
          </li>
          <li>
            Совокупная ответственность Администратора Сервиса ограничивается
            денежной суммой, уплаченной Пользователем по договору Исполнителю в
            качестве оплаты (по установленной Администратором Сервиса стоимости
            Тарифов, опубликованной на сайте tender-flow.ru) за доступ к Сервису
            за месяц, предшествующий моменту возникновения претензии у
            Пользователя.
          </li>
          <li>
            Стороны освобождаются от ответственности за нарушение условий
            Соглашения, если такое нарушение вызвано действием обстоятельств
            непреодолимой силы (форс-мажор), включая действия органов
            государственной власти, пожар, наводнение, землетрясение, другие
            стихийные действия, массовые отключения электроэнергии, забастовки,
            гражданские волнения, беспорядки, любые иные обстоятельства, которые
            могут повлиять на исполнение Соглашения и неподконтрольные Сторонам.
          </li>
        </ol>
      </article>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isAdmin'])
  }
}
</script>
